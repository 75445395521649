import React, { useState, useContext, useEffect } from "react";
import products_icon from "../../static/products_icon.png";
import categories_icon from "../../static/categories_icon.png";
import orders_icon from "../../static/orders_icon.png";
import settings_icon from "../../static/settings_icon.png";
import logout_icon from "../../static/logout_icon.png";
import { DataContext } from "../../common/DataContext";
import { StyledButton } from "../../components/common";
import closeIcon from "../../static/images/close_icon.png";

export const EditContactInfoModal = (props) => {
  const {
    updateContactInfo,
    fetchContactInfo,
    address,
    setAddress,
    phone,
    setPhone,
    email,
    setEmail,
    handleEmailChange,
    handlePhoneNumberChange,
    handleSetAddress,
  } = useContext(DataContext);

  useEffect(() => {
    fetchContactInfo();
  }, []);

  const handleUpdateContactInfo = () => {
    updateContactInfo();
    props.modal_handler();
  };

  return (
    <>
      {props.modal_open && props.modal_open === true ? (
        <>
          <div
            style={{
              position: "fixed",
              left: "40vw",
              top: "12vh",
              width: "40vw",
              height: "auto",
              backgroundColor: "ghostwhite",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              boxShadow: "1px 1px 6px 2px black",
              zIndex: "999",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "10vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "top",
                marginBottom: "1vh",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  right: "2%",
                  top: "2%",
                  width: "2vh",
                  height: "2vh",
                }}
                onClick={() => props.modal_handler()}
              >
                <img
                  style={{
                    width: "auto",
                    height: "2vh",
                    margin: "auto",
                  }}
                  src={closeIcon}
                />
              </div>
              <h2>{props.title}</h2>
            </div>

            <div
              style={{
                width: "100%",
                height: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "1vh",
              }}
            >
              <h2>Stately Stickers llc</h2>

              <div
                style={{
                  width: "95%",
                  height: "4vw",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "lightblue",
                  marginBottom: "1vh",
                }}
              >
                <h2>
                  <strong>Address:</strong>
                </h2>

                <input
                  type="text"
                  key="address"
                  value={address}
                  onChange={(e) => handleSetAddress(e)}
                  style={{
                    marginLeft: "2vw",
                    width: "25vw",
                    height: "3vh",
                  }}
                />
              </div>

              <div
                style={{
                  width: "95%",
                  height: "4vw",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "lightblue",
                  marginBottom: "1vh",
                }}
              >
                <h2>
                  <strong>Phone:</strong>
                </h2>
                <input
                  type="text"
                  placeholder="(123) 456-7890"
                  value={phone}
                  onChange={(e) => handlePhoneNumberChange(e)}
                  style={{
                    marginLeft: "2vw",
                    width: "25vw",
                    height: "3vh",
                  }}
                />
              </div>

              <div
                style={{
                  width: "95%",
                  height: "4vw",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "lightblue",
                  marginBottom: "1vh",
                }}
              >
                <h2>
                  <strong>Email:</strong>
                </h2>
                <input
                  type="email"
                  placeholder="example@example.com"
                  value={email}
                  onChange={(e) => handleEmailChange(e)}
                  style={{
                    marginLeft: "2vw",
                    width: "25vw",
                    height: "3vh",
                  }}
                />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: "10vh",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "2vh",
                marginTop: "2vh",
              }}
            >
              <StyledButton
                buttonText={"Cancel"}
                buttonAction={() => props.modal_handler()}
              />
              <StyledButton
                buttonText={"Edit"}
                buttonAction={() => handleUpdateContactInfo()}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export const EditServicesInfoModal = (props) => {
  const { servicesText, fetchServicesInfo, updateServicesInfo } =
    useContext(DataContext);

  const [tempServicesText, setTempServicesText] = useState("");

  useEffect(() => {
    // fetchServicesInfo();
  }, []);

  useEffect(() => {
    if (servicesText && servicesText !== null) {
      let tempText = servicesText;
      setTempServicesText(tempText);
    }
  }, [servicesText]);

  const handleSetTempServicesText = (e) => {
    setTempServicesText(e.target.value);
  };

  const handleUpdateSerivesInfo = () => {
    updateServicesInfo(tempServicesText);
    props.modal_handler();
  };

  return (
    <>
      {props.modal_open && props.modal_open === true ? (
        <>
          <div
            style={{
              position: "fixed",
              left: "40vw",
              top: "12vh",
              width: "40vw",
              height: "80%",
              backgroundColor: "ghostwhite",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              boxShadow: "1px 1px 6px 2px black",
              zIndex: "999",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "top",
                marginBottom: "1vh",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  right: "2%",
                  top: "2%",
                  width: "2vh",
                  height: "2vh",
                }}
                onClick={() => props.modal_handler()}
              >
                <img
                  style={{
                    width: "auto",
                    height: "2vh",
                    margin: "auto",
                  }}
                  src={closeIcon}
                />
              </div>
              <h2>{props.title}</h2>
            </div>

            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h2>Stately Stickers llc</h2>

              <div
                style={{
                  width: "95%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h2>
                  <strong>Services page text:</strong>
                </h2>
                <textarea
                  id="settingsText"
                  name="settingsText"
                  value={tempServicesText}
                  onChange={(e) => handleSetTempServicesText(e)}
                  style={{
                    marginLeft: "2vw",
                    width: "80%",
                    height: "80%",
                  }}
                ></textarea>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: "10vh",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "2vh",
                marginTop: "2vh",
              }}
            >
              <StyledButton
                buttonText={"Cancel"}
                buttonAction={() => props.modal_handler()}
              />
              <StyledButton
                buttonText={"Edit"}
                buttonAction={() => handleUpdateSerivesInfo()}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
