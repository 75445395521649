import React, { useState, useContext } from "react";
import { DataContext } from "./common/DataContext";
import closeIcon from "./static/images/close_icon.png";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

const Checkout = ({
  shoppingCart,
  subtotal,
  salesTax,
  total,
  shippingMethod,
  shippingPrice,
  streetAddress,
  name,
  city,
  unit,
  state,
  country,
  postalCode,
  email,
  modalHandler
}) => {
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
  const [currency, setCurrency] = useState(options.currency);
  var entry = null;

  const { createOrder,mobile } = useContext(DataContext);

  const onCurrencyChange = ({ target: { value } }) => {
    setCurrency(value);
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: value,
      },
    });
  };

  const onCreateOrder = (data, actions) => {
    // console.log("Shopping Cart:", shoppingCart);
    // console.log("Subtotal:", subtotal);
    // console.log("Total:", total);
    // console.log("Shipping Method:", shippingMethod);
    // console.log("Shipping Price:", shippingPrice);
    // console.log("Sales Tax:", salesTax.toFixed(2));
    const calculatedTotal = (subtotal + shippingPrice+salesTax).toFixed(2);
    // console.log("Calculated Total:", calculatedTotal);

    // Validate that total matches the calculated total
    if (calculatedTotal !== total.toFixed(2)) {
      // console.log(calculatedTotal, total.toFixed(2))
      // console.error("Total amount mismatch!");
      return;
    }
    
    let  tempEntry = [{
      description: "my item",
      amount: {
        currency_code: currency,
        value: calculatedTotal,
            breakdown: {
                item_total: {
                    currency_code: currency,
                    value: subtotal.toFixed(2).toString(),
                },
                shipping: {
                  currency_code: currency,
                  value: shippingPrice.toFixed(2).toString(),
                  method: shippingMethod,
                },
    tax_total: {
      currency_code: currency,
      value: salesTax.toFixed(2).toString(),
    },
    items:shoppingCart
}
      }
  }] 

    entry = tempEntry;

    return actions.order.create({
      purchase_units: tempEntry,
    });
  };

  const onApproveOrder = (data, actions) => {
    createOrder(
      entry,
      streetAddress,
      name,
      city,
      unit,
      state,
      country,
      postalCode,
      email,
      modalHandler
    );
    return actions.order.capture().then((details) => {
      const name = details.payer.name.given_name;
      alert(`Transaction completed by ${name}`);
    });
  };

  return (
    <div
      style={{
        position:'fixed',
        width:mobile?'75vw':'30vw',
        height:'75vh',
        top:mobile?'10%':'8%',
        left:mobile?'10%':'35%',
        overflowY:'scroll',
        backgroundColor:'black',
        padding:'2vw',
        boxShadow: "1px 1px 6px 2px grey",

      }}
    
    >
                          <div
                style={{
                  position: "absolute",
                  right: "2%",
                  top: mobile ? ".5vh" : "2%",
                  width: "4vh",
                  height: "4vh",
                  backgroundColor: "white",
                  display:'flex',
                  flexDirection:'column',
                  alignItems:'center'
                }}
                onClick={(e) =>modalHandler(e)}
              >
                <img
                  style={{
                    width: "auto",
                    height: "2vh",
                    margin: "auto",
                  }}
                  src={closeIcon}
                />
              </div>
    <div className="checkout">
      {isPending ? (
        <p>LOADING...</p>
      ) : (
        <>
          <select
            style={{ marginBottom: "1vh" }}
            value={currency}
            onChange={onCurrencyChange}
          >
            <option value="USD">💵 USD</option>
            <option value="CAD">💶 CAD</option>
            <option value="EUR">💶 Euro</option>
          </select>
          <PayPalButtons
            style={{ layout: "vertical" }}
            createOrder={(data, actions) => onCreateOrder(data, actions)}
            onApprove={(data, actions) => onApproveOrder(data, actions)}
          />
          {/* <button onClick={()=>onApproveOrder('','')}>test</button> */}
        </>
      )}
    </div>
    </div>
  );
};

export default Checkout;
