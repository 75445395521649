import React, { useState, useContext, useEffect } from "react";
import products_icon from "../../static/products_icon.png";
import categories_icon from "../../static/categories_icon.png";
import orders_icon from "../../static/orders_icon.png";
import settings_icon from "../../static/settings_icon.png";
import logout_icon from "../../static/logout_icon.png";
import upIcon from "../../static/images/upIcon.png";
import downIcon from "../../static/images/downIcon.png";
import leftIcon from "../../static/images/leftIcon.png";
import rightIcon from "../../static/images/rightIcon.png";
import closeIcon from "../../static/images/close_icon.png";

import { DataContext } from "../../common/DataContext";
import { StyledButton } from "../../components/common";

export const EditCategoryModal = (props) => {
  const {
    selectedCategoryImage,
    handleSetNewCategoryImage,
    selectedSubCategoryImage,
    handleSetNewSubCategoryImage,
    newCategoryURL,
    editCategoryMode,
    setEditCategoryMode,
    subcategoryName,
    subcategoryDesc,
    handleSetSubcategoryName,
    setNewSubCategoryImage,
    createCategory,
    handleSetSubcategoryDec,
    setSelectedSubCategoryImage,
    deleteCategory,
    editCategory,
    fetchSubCategories,
    currentSubCategories, setCurrentSubCategories,
  } = useContext(DataContext);

  const [subcategorySectionOpen, setSubcategorySectionOpen] = useState(false);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  useEffect(() => {
      fetchSubCategories(props.selectedCategoryID)
  }, [props.selectedCategoryID]);




  const handleToggleAddSubcategory = (e) => {
    setEditCategoryMode("sub");
    setSubcategorySectionOpen(!subcategorySectionOpen);
    if (e) {
      props.toggleAddSubcategoryOpen(e);
    } else {
      props.toggleAddSubcategoryOpen();
    }
  };

  const handleAddSubcategory = () => {
    createCategory(
      subcategoryName,
      subcategoryDesc,
      "sub",
      props.selectedCategoryID,
    );

    fetchSubCategories( props.selectedCategoryID)

    props.toggleAddSubcategoryOpen(false);

  };

  const handleDeleteSubcategory = () => {
    deleteCategory(selectedSubcategory.id,"sub",props.selectedCategoryID);
    handleSetSubcategoryName({ target: { value:''} });
    handleSetSubcategoryDec({ target: { value: '' } });
    setNewSubCategoryImage(null);
    setSelectedSubCategoryImage(null);
  };

  const handleUpdateSubcategory = ()=>{
    editCategory(
      selectedSubcategory.id,
      subcategoryName,
      subcategoryDesc,
      "sub",
      
    )
  }

  const handleSubcategorySelect = (selectsubcategory) => {

    if(selectsubcategory !== selectedSubcategory){
      setSelectedSubcategory(selectsubcategory);
      handleSetSubcategoryName({ target: { value: selectsubcategory.name } });
      handleSetSubcategoryDec({ target: { value: selectsubcategory.description } });
      setNewSubCategoryImage(null);
      if (selectsubcategory.image) {
        setSelectedSubCategoryImage(selectsubcategory.image.url);
      }
    }
    else{
      setSelectedSubcategory(null);
      handleSetSubcategoryName({ target: { value: '' } });
      handleSetSubcategoryDec({ target: { value: '' } });
      setNewSubCategoryImage(null);
      setSelectedSubCategoryImage(null);

    }

  };

  return (
    <>
      {props.modal_open && props.modal_open === true ? (
        <div
          style={{
            position: "fixed",
            left: "40vw",
            top: "12vh",
            width: "40vw",
            height: "76vh",
            backgroundColor: "ghostwhite",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            border: "1px solid black",
            zIndex: "999",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "10vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "top",
              marginBottom: "1vh",
              borderBottom: "1px solid black",
            }}
          >
            <div
              style={{
                position: "absolute",
                right: "2%",
                top: "2%",
                width: "2vh",
                height: "2vh",
              }}
              onClick={() => props.modal_handler()}
            >
              <img
                style={{
                  width: "auto",
                  height: "2vh",
                }}
                src={closeIcon}
              />
            </div>
            <h2>{props.title}</h2>
          </div>

          {/* Content Container with Vertical Scrolling */}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              overflowY: "scroll",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h3 style={{ marginLeft: "1vw" }}>Category Name:</h3>
              <input
                type="text"
                value={props.newCategoryName}
                onChange={(e) => props.handleSetNewCategoryName(e)}
                style={{
                  height: "50%",
                  width: "50%",
                  marginRight: "1vw",
                }}
              />
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h3 style={{ marginLeft: "1vw" }}>Category Description:</h3>
              <input
                type="text"
                value={props.newCategoryDesc}
                onChange={(e) => props.handleSetNewCategoryDesc(e)}
                style={{
                  height: "50%",
                  width: "50%",
                  border: "1px solid grey",
                  marginRight: "1vw",
                }}
              />
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "6vh",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid black",
                }}
              >
                <h3 style={{ marginLeft: "1vw" }}>Category Image:</h3>
                <img
                  style={{
                    width: "3rem",
                    height: "auto",
                  }}
                  src={
                    selectedCategoryImage && selectedCategoryImage.length > 0
                      ? selectedCategoryImage[0].url
                      : newCategoryURL
                  }
                />
                <input
                  type="file"
                  accept=".jpg, .png, .jpeg,"
                  onChange={(e) => handleSetNewCategoryImage(e)}
                  style={{
                    height: "50%",
                    width: "50%",
                    border: "1px solid grey",
                    marginRight: "1vw",
                  }}
                />
              </div>
            </div>

            {/* Subcategory List */}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: "1vh",
              }}
            >
                                            <label
                style={{
                  fontSize: "1rem",
                  color: "black",
                  fontWeight: 700,
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                Subcategories:

              </label>
              <div
                style={{
                  width: "98%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  overflowX: "scroll",
                  padding: "1vh",
                  gap :'1vh',
                  marginBottom:'1vh'
                }}
              >

                {currentSubCategories&&currentSubCategories.map((subcategory) => (
                  <div
                    key={subcategory.id}
                    style={{
                      width: "auto",
                      minWidth:'4vw',
                      height:'auto',
                      marginBottom: "1vh",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems:'center',
                      // gap:'1vw',
                      border: "1px solid black",
                      padding: "1vh",
                      cursor: "pointer",
                      backgroundColor:
                        selectedSubcategory?.id === subcategory.id
                          ? "lightblue"
                          : "white",
                    }}
                    onClick={(e) => handleSubcategorySelect(subcategory)}
                  >
                    <div>
                    <label
                style={{
                  fontSize: ".75rem",
                  color: "black",
                  fontWeight: 700,
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                {subcategory.name}
                </label>

                    </div>
                    {subcategory.image && (
                      <img
                        style={{
                          height: "2rem",
                          width: "auto",
                        }}
                        src={subcategory.image.url}
                        alt={subcategory.name}
                      />
                    )}
                  </div>
                ))}
              </div>

              {/* Subcategory Input Fields */}
              <div
                style={{
                  width: "100%",
                  height: "auto",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >


                <label
                style={{
                  fontSize: "1rem",
                  paddingLeft:'1vw',
                  color: "black",
                  fontWeight: 600,
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                Name:

              </label>

                <input
                  type="text"
                  style={{
                    height: "50%",
                    width: "50%",
                    border: "1px solid grey",
                    marginRight: "1vw",
                  }}
                  value={subcategoryName}
                  onChange={(e) => handleSetSubcategoryName(e)}
                />
              </div>

              <div
                style={{
                  width: "100%",
                  // height: "6vh",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                                <label
                style={{
                  fontSize: "1rem",
                  paddingLeft:'1vw',
                  color: "black",
                  fontWeight: 600,
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                Description:

              </label>


                <input
                  type="text"
                  style={{
                    height: "50%",
                    width: "50%",
                    border: "1px solid grey",
                    marginRight: "1vw",
                  }}
                  value={subcategoryDesc}
                  onChange={(e) => handleSetSubcategoryDec(e)}
                />
              </div>

              <div
                style={{
                  width: "100%",
                  height: "5vh",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >


                <label
                style={{
                  fontSize: "1rem",
                  paddingLeft:'1vw',
                  color: "black",
                  fontWeight: 600,
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
               Subcategory Image:

              </label>

                {selectedSubCategoryImage &&
                selectedSubCategoryImage !== null ? (
                  <>
                    <img
                      src={selectedSubCategoryImage}
                      style={{
                        width: "2vw",
                        height: "auto",
                      }}
                    />
                  </>
                ) : null}
                <input
                  type="file"
                  accept=".jpg, .png, .jpeg,"
                  onChange={(e) => handleSetNewSubCategoryImage(e, "edit")}
                  style={{
                    height: "50%",
                    width: "50%",
                    border: "1px solid grey",
                    marginRight: "1vw",
                  }}
                />
              </div>

              <div
                style={{
                  width: "100%",
                  height: "6vh",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <StyledButton
                  buttonText={"Cancel"}
                  buttonAction={() => props.modal_handler()}
                />
                <StyledButton
                  buttonText={selectedSubcategory===null?"Add":'Update'}
                  buttonAction={() => selectedSubcategory===null?handleAddSubcategory():handleUpdateSubcategory()}
                />
                <StyledButton
                  buttonText={"Delete"}
                  buttonAction={() => handleDeleteSubcategory()}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: "10vh",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "2vh",
              marginTop: "2vh",
              borderTop: "1px solid black",
            }}
          >
            <StyledButton
              buttonText={"Cancel"}
              buttonAction={() => props.modal_handler()}
            />
            <StyledButton
              buttonText={"Edit"}
              buttonAction={() => props.handleEditCategory()}
            />
            {/* <StyledButton
              buttonText={"Subcategories"}
              buttonAction={handleToggleAddSubcategory}
            /> */}
          </div>
        </div>
      ) : null}
    </>
  );
};

export const AddCategoryModal = (props) => {
  const {
    newCategoryURL,
    setNewCategoryURL,
    handleSetNewCategoryImage,
    editCategoryMode,
    setEditCategoryMode,
  } = useContext(DataContext);

  return (
    <>
      {props.modal_open && props.modal_open === true ? (
        <>
          <div
            style={{
              position: "fixed",
              left: "40vw",
              top: "12vh",
              width: "40vw",
              height: "auto",
              backgroundColor: "ghostwhite",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "top",
              boxShadow: "1px 1px 6px 2px black",
              zIndex: editCategoryMode === "main" ? "999" : "9999",
            }}
          >
            <div
              style={{
                position: "absolute",
                right: "2%",
                top: "2%",
                width: "2vh",
                height: "2vh",
                // backgroundColor: "black",
              }}
              onClick={() => props.modal_handler()}
            >
              <img
                style={{
                  width: "auto",
                  height: "2vh",
                  margin: "auto",
                }}
                src={closeIcon}
              />
            </div>
            <h2>
              {editCategoryMode && editCategoryMode === "main"
                ? "Add Category"
                : "Add Subcategory"}
            </h2>
            <div
              style={{
                width: "100%",
                height: "10vh",
                // backgroundColor:'grey',
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <h2
                style={{
                  marginLeft: "1vw",
                }}
              >
                {editCategoryMode && editCategoryMode === "main"
                  ? "Category Name:"
                  : "Subategory Name:"}
              </h2>
              <input
                type="text"
                value={props.newCategoryName}
                onChange={(e) => props.handleSetNewCategoryName(e)}
                style={{
                  height: "50%",
                  width: "65%",
                  marginLeft: "2vw",
                  boxShadow: "1px 1px 6px 2px grey",
                }}
              />
            </div>

            <div
              style={{
                width: "100%",
                height: "10vh",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <h2
                style={{
                  marginLeft: "1vw",
                  width: "26%",
                }}
              >
                {"Category Description:"}
              </h2>
              <input
                type="text"
                value={props.newCategoryDesc}
                onChange={(e) => props.handleSetNewCategoryDesc(e)}
                style={{
                  height: "50%",
                  width: "65%",
                  marginLeft: "1vw",
                  boxShadow: "1px 1px 6px 2px grey",
                }}
              />
            </div>

            <div
              style={{
                width: "100%",
                height: "auto",
                // backgroundColor: "lightgrey",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                style={{
                  width: "25%",
                  height: "auto",
                }}
                src={newCategoryURL}
              />
            </div>
            <div
              style={{
                width: "100%",
                height: "4vh",
                // backgroundColor: "grey",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <h2
                style={{
                  marginLeft: "1vw",
                  width: "24.5%",
                }}
              >
                {"Image:"}
              </h2>

              <input
                type="file"
                accept=".jpg, .png, .jpeg,"
                onChange={(e) => handleSetNewCategoryImage(e)}
              />
            </div>
            <div
              style={{
                width: "100%",
                height: "10vh",
                // backgroundColor:'grey',
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "2vh",
              }}
            >
              <StyledButton
                buttonText={"Cancel"}
                buttonAction={() => props.handleCancelCategory()}
              />
              <StyledButton
                buttonText={"Create"}
                buttonAction={() => props.handleAddCategory()}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export const CategoryWidget = (props) => {
  return (
    <div
      style={{
        width: "18vw",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        marginLeft: "1vw",
        marginTop: "2vh",
        marginBottom: "1vh",
        padding: "auto",
        backgroundColor:
          props.selectedCategoryID === props.category_id ? "#87CEFA" : "white",
        boxShadow: "1px 1px 6px 2px black",
        // alignItems: "center",
        justifyContent: "top",
      }}
      value={props.category_id}
      key={props.category_id}
      onClick={(e) =>
        props.handleSetSelectedCategoryID(
          e,
          props.category_id,
          props.category_name,
          props.category_desc,
          props.images,
          props.subcategories,
        )
      }
    >
      <div
        style={{
          width: "18vw",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          // backgroundColor:'green'
        }}
      >
        {/* 
        <img
          style={{ width: "18rem", height: "auto", marginTop: "2vh" }}
          src={props.image.url}
          onClick={(e) => props.handleTogglePreviewModalOpen(e, props.images)}
        /> */}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
          width: "100%",
          alignItems: "center",
          marginBottom: "1vh",
          marginTop: "1vh",
        }}
      >
        <strong>{"Category Name:"}</strong>
        {props.category_name}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
          width: "100%",
          alignItems: "center",
          marginBottom: "1vh",
        }}
      >
        <strong>{"Category Description:"}</strong>
        {props.category_desc}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
          width: "100%",
          alignItems: "center",
          marginBottom: "0vh",
        }}
      >
        <strong>{`Image:`}</strong>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "22rem",
            alignItems: "center",
            justifyContent: "left",
            marginTop: "1vh",
            overflowX: "scroll",
            marginBottom: "1vh",
            // backgroundColor:'grey'
          }}
        >
          {props.images.slice().map((row) => {
            const { url } = row;
            return (
              <>
                <img
                  style={{
                    width: "15rem",
                    height: "auto",
                    margin: "auto",
                  }}
                  src={url}
                />
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

// export const ControlButtonsWidget=(props)=>{
//     return(
//         <>
//               <div
//                 style={{
//                   width:'20%',
//                   display:'flex',
//                   flexDirection:'column',
//                   width:'22%',
//                   height:'80%',
//                   backgroundColor:'white',
//                   marginLeft:'2vw',

//                 }}
//               >
//                 <div
//                   style={{

//                     display:'flex',
//                     flexDirection:'row',
//                     width:'100%',
//                     height:'25%',
//                     // backgroundColor:'green',
//                     alignItems:'center',
//                     marginBottom:'1vh',
//                     paddingLeft:'1vw'

//                   }}
//                 >
//                   <h1>
//                     {<strong>Orders:</strong>}
//                   </h1>
//                 </div>

//                 <div
//                   style={{

//                     display:'flex',
//                     flexDirection:'row',
//                     width:'100%',
//                     height:'20%',
//                     // backgroundColor:'lightblue',
//                     alignItems:'center',
//                     marginBottom:'1vh',
//                     paddingLeft:'1vw'

//                   }}
//                 >
//                   <h2>
//                     {<strong>New:</strong>}
//                   </h2>
//                 </div>

//                 <div
//                   style={{

//                     display:'flex',
//                     flexDirection:'row',
//                     width:'100%',
//                     height:'20%',
//                     // backgroundColor:'lightcoral',
//                     alignItems:'center',
//                     marginBottom:'1vh',
//                     paddingLeft:'1vw'
//                   }}
//                 >
//                   <h2>
//                     {<strong>Completed:</strong>}
//                   </h2>
//                 </div>

//                 <div
//                   style={{

//                     display:'flex',
//                     flexDirection:'row',
//                     width:'100%',
//                     height:'20%',
//                     // backgroundColor:'lightyellow',
//                     alignItems:'center',
//                     paddingLeft:'1vw'
//                   }}
//                 >
//                   <h2>
//                     {<strong>Refunded:</strong>}
//                   </h2>
//                 </div>

//               </div>
//         </>
//     )
// }

{
  /* <img
                          style={{
                            width: "auto",
                            height: "2vh",
                            margin: "auto",
                          }}
                          src={closeIcon}
                          // onClick={() => props.handleDeleteSelectedProductImage(index)}
                        />
                      <img
                        style={{
                          width: "5rem",
                          height: "6rem",
                          // margin:'auto'
                        }}
                        src={selectedCategoryImage[0].url}
                      />
                     */
}
