import React, { useState, useContext, useEffect } from "react";
import { DataContext } from "../../common/DataContext";

export const AboutUsText = () => {
  const { cartOpen, toggleCartOpen, mobile, handleToggleCartOpen } =
    useContext(DataContext);

  const aboutUsText = `
Welcome to Stately Stickers!

This little shop is run by just one person with way too many hobbies and a strong desire to justify them by calling it "work."

My humble setup consists of a few machines, a pile of materials, and a lot of enthusiasm (most days). 

The focus here? State flag stickers (especially Colorado’s, because it’s awesome) and a mix of other random sticker designs I’ve cooked up.

But why stop at stickers when I’ve got so many hobbies to monetize?

Alongside the stickers, you’ll find 3D prints, handcrafted chainmaille jewelry & armor (because, why not?), vector art, and other creations that keep me entertained.

I’m not a big-time operation, so massive custom orders probably aren’t in the cards, but I’m all about making cool, personalized stuff that’s just a bit different from the usual.

Got a wild idea or a special request? Hit me up! I can’t guarantee I’ll be able to pull it off, but I’m always down for a challenge, and we might figure something out together.

Thanks for supporting my sticker-slinging, hobby-justifying venture. Hopefully, you find something here you’ll like!
  

    `;
  const ParagraphComponent = ({ text }) => {
    return (
      <div
        style={{
          width: mobile ? "80%" : "50%",
          height: "70vh",
          overflowY: mobile ? "scroll" : "",
          whiteSpace: "pre-line",
          color: "black",
          textDecoration: "none",
          textAlign: "left",
          marginBottom: mobile ? "" : "1vh",
          fontWeight: 500,
          paddingBottom: "5vh",
        }}
      >
        {text}
      </div>
    );
  };

  return (
    <>
      {/* <label
        style={{
          paddingTop: mobile ? "" : "2vh",
          fontSize:'1.25rem',
          fontWeight:700,
          marginBottom: "1vh",
          marginTop: "1vh",
          color: "white",
          textAlign: "center",
          color: "black",
        }}
      >
        About Us
      </label> */}

      <ParagraphComponent text={aboutUsText} />
    </>
  );
};
