import React, { useState, useContext } from "react";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { poster, fetcher } from "../../calls/calls";
// import './styles.css';
import { API_URL } from "../../common/constants";
export const Register = () => {
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");

  const registerUser = async () => {
    let url = API_URL + "/register";
    let outpack = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      code: code,
    };
    poster(outpack, url).then((response) => {
    });
  };

  return (
    <>
      <Header />
      <div>
        <h1>Register</h1>
        <form>
          <div>
            <label>First Name: </label>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstname(e.target.value)}
              id=""
            />
          </div>
          <div>
            <label>Last Name: </label>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastname(e.target.value)}
              id=""
            />
          </div>
          <div>
            <label>Email: </label>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id=""
            />
          </div>
          <div>
            <label>Password: </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              id=""
            />
          </div>
          <div>
            <label>Code: </label>
            <input
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              id=""
            />
          </div>
          <button type="button" onClick={() => registerUser()}>
            Submit
          </button>
        </form>
      </div>
      <Footer />
    </>
  );
};
