import "./styles.css";
import styled from "styled-components";
import React, { useState, useContext, useEffect } from "react";
import { DataContext } from "../../common/DataContext";

export const Footer = () => {
  const {
    cartOpen,
    toggleCartOpen,
    mobile,
    handleToggleCartOpen,
    handleNavigate,
  } = useContext(DataContext);

  return (
    <div
      style={{
        fontFamily: "sans-serif",
        position: "fixed",
        top: "92.5%",
        marginLeft: "0vw",
        height: "8%",
        fontWeight: "500",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: mobile ? "column" : "row",
        justifyContent: "left",
        gap: mobile ? "" : "70%",
        width: "100%",
        backgroundColor: "#050505",
        paddingTop: "1vh",
      }}
    >
      <div
        style={{
          width: "auto",
          display: "flex",
          height: "auto",

          justifyContent: mobile ? "center" : "left",
          textAlign: mobile ? "center" : "left",
          alignItems: "center",
          paddingLeft: mobile ? null : "1.5vh",
          // backgroundColor:'green',
          fontWeight: "500",
          color: " #f2e9ea",
          fontSize: mobile ? "1rem" : "1.25rem",
        }}
      >
        &copy;{new Date().getFullYear()} Stately Stickers llc. All rights
        reserved
      </div>
      <label
        style={{
          width: "auto",
          display: "flex",
          height: "auto",
          justifyContent: mobile ? "center" : "left",
          textAlign: mobile ? "center" : "left",
          alignItems: "center",
          paddingLeft: mobile ? null : "1.5vh",
          // backgroundColor:'green',
          fontWeight: "500",
          color: " #f2e9ea",
          fontSize: mobile ? "1rem" : "1.25rem",
        }}
        onClick={(e) => handleNavigate("/Privacy")}
      >
        Privacy Policy
      </label>
    </div>
  );
};
