import React, { useEffect, useContext } from "react";
import { DataContext } from "../../common/DataContext";
import { AuthContext } from "../../common/AuthContext";
import {
  OrdersWidget,
  SalesWidget,
  TrafficWidget,
} from "./adminDash_Components";
import "./styles.css";
import { Sidebar } from "../../components/common";
import { Navigate } from "react-router-dom";

export const AdminDashboard = () => {
  const {
    setSelectedRow,
    fetchAdminStats,
    totalProcessing,
    setTotalProcessing,
    totalCompleted,
    setTotalCompleted,
    totalRefunded,
    setTotalRefunded,
    handleNavigate,
  } = useContext(DataContext);
  const { user, redirect, toggleRedirect } = useContext(AuthContext);

  useEffect(() => {
    if (user === null) {
      handleNavigate("/login");
    }
    if (user && user.role != "Admin") {
      handleNavigate("/login");
    } else if (user && user.role == "Admin") {
      fetchAdminStats();
    }
  }, [user]);

  useEffect(() => {
    setSelectedRow("Dashboard");
  }, []);

  return (
    <>
      <div
        className="adminDash"
        style={{ display: "flex", flexDirection: "row" }}
      >
        <Sidebar />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              height: "30%",
              // backgroundColor:'grey',
              alignItems: "center",
            }}
          >
            <OrdersWidget
              totalProcessing={totalProcessing}
              totalCompleted={totalCompleted}
              totalRefunded={totalRefunded}
            />
            <SalesWidget />
            <TrafficWidget />

            {/* <div
                style={{

                  display:'flex',
                  flexDirection:'row',
                  width:'22%',
                  height:'80%',
                  backgroundColor:'white',
                  marginLeft:'2vw',

                }}
              >

              </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
