import "./App.css";
import React, { useEffect } from "react";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { DataProvider } from "./common/DataContext/index";
import { AuthProvider } from "./common/AuthContext";
import { Main } from "./pages/Main";
import { About } from "./pages/About";

import { Services } from "./pages/Services";
import { Contact } from "./pages/contact";
import { Shop } from "./pages/shop";
import { Login } from "./pages/login";
import { Register } from "./pages/Register";
import { AdminDashboard } from "./pages/AdminDashboard";
import { AdminProductsPage } from "./pages/AdminProducts";
import { AdminCategoriesPage } from "./pages/AdminCategories";
import { AdminOrdersPage } from "./pages/AdminOrders";
import { AdminSettingsPage } from "./pages/AdminSettings";
import { Product } from "./pages/product";
import { PrivacyPolicy } from "./pages/Privacy";
import { FAQ } from "./pages/FAQ";

function App() {
  useEffect(() => {
    document.title = "Stately Stickers";

  }, []);

  return (
    <>
    
      <BrowserRouter>
        <AuthProvider>
          <DataProvider>
            <Routes>
              <Route exact path="/" element={<Main />} />
              <Route
                exact
                path="/adminDashboard"
                element={<AdminDashboard />}
              />
              <Route
                exact
                path="/adminProducts"
                element={<AdminProductsPage />}
              />
              <Route
                exact
                path="/adminCategories"
                element={<AdminCategoriesPage />}
              />
              <Route exact path="/adminOrders" element={<AdminOrdersPage />} />
              <Route
                exact
                path="/adminSettings"
                element={<AdminSettingsPage />}
              />
              <Route exact path="/register" element={<Register />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/admin" element={<AdminDashboard />} />
              <Route exact path="/about" element={<About />} />
              <Route exact path="/Privacy" element={<PrivacyPolicy />} />
              <Route exact path="/services" element={<Services />} />
              <Route exact path="/contact" element={<Contact />} />
              <Route exact path="/product/:productName" element={<Product />} />{" "}
              <Route exact path="/shop" element={<Shop />} />
              <Route exact path="/shop/:categoryName" element={<Shop />} />{" "}
              {/* Dynamic Route for Shop Categories */}
              <Route exact path="/FAQ" element={<FAQ />} />
            </Routes>
          </DataProvider>
        </AuthProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
