import React, { useState, useContext, useEffect } from "react";
import useToggle from "../../hooks/useToggle";
import "./styles.css";
import { ConfirmModal, Sidebar, StyledButton } from "../../components/common";
import { DataContext } from "../../common/DataContext";
import { AuthContext } from "../../common/AuthContext";
import {
  EditContactInfoModal,
  EditServicesInfoModal,
} from "./adminSettingsComponents";

export const AdminSettingsPage = () => {
  const [contactInfoOpen, toggleContactInfoOpen] = useToggle();
  const [servicesInfoOpen, toggleServicesInfoOpen] = useToggle();

  const { handleSetSelectedRow, handleNavigate } = useContext(DataContext);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user === null) {
      handleNavigate("/login");
    }
    if (user && user.role != "Admin") {
      handleNavigate("/login");
    } else if (user && user.role == "Admin") {
      handleSetSelectedRow("Settings");
    }
  }, [user]);

  const handleOpenContactInfo = () => {
    toggleContactInfoOpen();
  };

  const handleOpenServicesInfo = () => {
    toggleServicesInfoOpen();
  };

  return (
    <>
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "row",
        backgroundColor: "rgb(78, 171, 218)",
      }}
    >
      <EditContactInfoModal
        modal_open={contactInfoOpen}
        modal_handler={handleOpenContactInfo}
      />
      <EditServicesInfoModal
        modal_open={servicesInfoOpen}
        modal_handler={handleOpenServicesInfo}
      />
        <Sidebar />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                height: "10%",
                backgroundColor: "ghostwhite",
                boxShadow: "1px 1px 6px 2px black",
                alignItems: "center",
                justifyContent: "right",
              }}
            >
              <StyledButton
                buttonText={"Contact Info"}
                buttonAction={handleOpenContactInfo}
              />

              <StyledButton
                buttonText={"Services Info"}
                buttonAction={handleOpenServicesInfo}
              />
              <StyledButton
                buttonText={"About Info"}
              />
            </div>
          </>
          <>
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(18vw, 1fr))",
                gridGap: "1vw",
                maxHeight: "100%",
                overflowY: "scroll",
                paddingTop: "2vh",
                paddingBottom: "2vh",
              }}
            >
            </div>
          </>
        </div>
      </div>

    </>
  );
};
