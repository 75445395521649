import React, { useState, useContext, useEffect } from "react";
import "./styles.css";
import { AuthContext } from "../../common/AuthContext";
import { ConfirmModal, Sidebar, StyledButton } from "../../components/common";
import { DataContext } from "../../common/DataContext";
import useToggle from "../../hooks/useToggle";
import {
  AddCategoryModal,
  EditCategoryModal,
  CategoryWidget,
} from "./adminCategoriesComponents";
export const AdminCategoriesPage = () => {
  const { handleSetSelectedRow } = useContext(DataContext);
  const {
    currentCategories,
    setCurrentCategories,
    createCategory,
    newCategoryImage,
    setNewCategoryImage,
    setNewCategoryURL,
    handleNavigate,
    fetchCategories,
    deleteCategory,
    setSelectedCategoryImage,
    editCategory,
    editCategoryMode,
    setEditCategoryMode,
    fetchSubCategories,
    currentSubCategories, setCurrentSubCategories,
  } = useContext(DataContext);
  const { user } = useContext(AuthContext);
  const [newCategoryName, setNewCategoryName] = useState(null);
  const [newCategoryDesc, setNewCategoryDesc] = useState(null);
  const [newCategorySubs, setNewCategorySubs] = useState(null);

  const [selectedCategoryID, setSelectedCategoryID] = useState(null);
  const [addSubCategoryOpen, toggleAddSubCategoryOpen] = useToggle();
  const [addCategoryOpen, toggleAddCategoryOpen] = useToggle();
  const [editCategoryOpen, toggleEditCategoryOpen] = useToggle();
  const [deleteCategoryOpen, toggleDeleteCategoryOpen] = useToggle();
  const [confirmModalOpen, toggleConfirmwModalOpen] = useToggle(false);

  useEffect(() => {
    if (user === null) {
      handleNavigate("/login");
    }
    if (user && user.role != "Admin") {
      handleNavigate("/login");
    } else if (user && user.role == "Admin") {
      fetchCategories();
      handleSetSelectedRow("Categories");
    }
  }, [user]);

  const handleToggleConfirmModalOpen = () => {
    toggleConfirmwModalOpen();
  };

  const handleSetSelectedCategoryID = (e, id, name, desc, images, subs) => {
    e.stopPropagation();
    if (id === selectedCategoryID) {
      handleClearForm();
    } else {
      setSelectedCategoryID(id);
      setNewCategoryName(name);
      setNewCategoryDesc(desc);
      setNewCategorySubs(subs);
      setSelectedCategoryImage(images);
    }
  };

  const handleClearForm = () => {
    setSelectedCategoryID(null);
    setNewCategoryName(null);
    setNewCategoryDesc(null);
    setSelectedCategoryImage(null);
    setNewCategoryURL(null);
  };

  const handleSetNewCategoryName = (e) => {
    setNewCategoryName(e.target.value);
  };

  const handleSetNewCategoryDesc = (e) => {
    setNewCategoryDesc(e.target.value);
  };

  const handleToggleAddCategoryModal = () => {
    setEditCategoryMode("main");
    toggleAddCategoryOpen();
    handleClearForm();
  };

  const handleToggleAddSubCategoryModal = () => {
    setEditCategoryMode("sub");
    toggleAddSubCategoryOpen();
  };
  const handleToggleEditCategoryModal = () => {
    toggleEditCategoryOpen();
    // handleClearForm();
  };

  const handleAddCategory = () => {
    createCategory(newCategoryName, newCategoryDesc);
    handleClearForm();
    toggleAddCategoryOpen();
  };

  const handleCancelCategory = () => {
    setNewCategoryDesc(null);
    setNewCategoryName(null);
    setNewCategoryImage(null);
    setNewCategoryURL(null);
    toggleAddCategoryOpen(false);
  };

  const handleDeleteCategory = () => {
    deleteCategory(selectedCategoryID,"main");
    handleClearForm();
    toggleConfirmwModalOpen(false);
  };
  const handleEditCategory = () => {
    editCategory(selectedCategoryID, newCategoryName, newCategoryDesc);
    handleClearForm();
    handleToggleEditCategoryModal();
  };

  return (
    <>
      <ConfirmModal
        question={"Are you sure you want to delete this category?"}
        modalHandler={handleToggleConfirmModalOpen}
        modalState={confirmModalOpen}
        confirmAction={handleDeleteCategory}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      ></div>
      <AddCategoryModal
        modal_open={addCategoryOpen}
        modal_handler={handleToggleAddCategoryModal}
        newCategoryName={newCategoryName}
        handleSetNewCategoryName={handleSetNewCategoryName}
        newCategoryDesc={newCategoryDesc}
        handleSetNewCategoryDesc={handleSetNewCategoryDesc}
        handleAddCategory={handleAddCategory}
        handleCancelCategory={handleCancelCategory}
      />

      <EditCategoryModal
        modal_open={editCategoryOpen}
        modal_handler={handleToggleEditCategoryModal}
        title={"Edit Category"}
        selectedCategoryID={selectedCategoryID}
        newCategoryName={newCategoryName}
        handleSetNewCategoryName={handleSetNewCategoryName}
        newCategoryDesc={newCategoryDesc}
        handleSetNewCategoryDesc={handleSetNewCategoryDesc}
        handleEditCategory={handleEditCategory}
        addSubcategoryOpen={addSubCategoryOpen}
        toggleAddSubcategoryOpen={handleToggleAddSubCategoryModal}
        subcategories={newCategorySubs}
        // handleToggleAddSubcategory={handleToggleAddCategoryModal }
      />

      <div
        className="adminProjects"
        style={{ display: "flex", flexDirection: "row" }}
      >
        <Sidebar />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                height: "10%",
                backgroundColor: "ghostwhite",
                boxShadow: "1px 1px 6px 2px black",
                alignItems: "center",
                justifyContent: "right",
              }}
            >
              <StyledButton
                buttonText={"Add"}
                buttonAction={handleToggleAddCategoryModal}
              />
              <StyledButton
                buttonText={"Edit"}
                buttonAction={handleToggleEditCategoryModal}
              />
              <StyledButton
                buttonAction={() => handleToggleConfirmModalOpen()}
                buttonText={"Delete"}
              />
            </div>
          </>
          <>
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(18vw, 1fr))",
                gridGap: "1vw",
                maxHeight: "100%",
                overflowY: "scroll",
                paddingTop: "2vh",
                paddingBottom: "2vh",
                // backgroundColor: "green",
              }}
              onClick={(e) => handleSetSelectedCategoryID(e, null)}
            >
              {currentCategories && currentCategories ? (
                <>
                  {currentCategories.slice().map((row) => {
                    const {
                      category_id,
                      category_name,
                      category_desc,
                      images,
                      subcategories,
                    } = row;

                    return (
                      <>
                        <CategoryWidget
                          category_id={category_id}
                          category_name={category_name}
                          category_desc={category_desc}
                          selectedCategoryID={selectedCategoryID}
                          subcategories={subcategories}
                          handleSetSelectedCategoryID={
                            handleSetSelectedCategoryID
                          }
                          images={images}
                        />
                      </>
                    );
                  })}
                </>
              ) : null}
            </div>
          </>

          {/* <img style={{width:'50vw',height:'auto'}}src={'https://printsmiths.nyc3.digitaloceanspaces.com/Images/octopus-image-1.jpg'}/> */}
        </div>
      </div>
    </>
  );
};
