import React, { useState } from "react";

export const OrdersWidget = (props) => {
  return (
    <>
      <div
        style={{
          width: "20%",
          display: "flex",
          flexDirection: "column",
          width: "22%",
          height: "80%",
          backgroundColor: "white",
          marginLeft: "2vw",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "25%",
            // backgroundColor:'green',
            alignItems: "center",
            marginBottom: "1vh",
            paddingLeft: "1vw",
          }}
        >
          <h1>{<strong>Orders:</strong>}</h1>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "20%",
            // backgroundColor:'lightblue',
            alignItems: "center",
            marginBottom: "1vh",
            paddingLeft: "1vw",
          }}
        >
          <h2>{<strong>New:</strong>}</h2>
          <h2>{<strong>{props.totalProcessing}</strong>}</h2>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "20%",
            // backgroundColor:'lightcoral',
            alignItems: "center",
            marginBottom: "1vh",
            paddingLeft: "1vw",
          }}
        >
          <h2>{<strong>Completed:</strong>}</h2>
          <h2>{<strong>{props.totalCompleted}</strong>}</h2>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "20%",
            // backgroundColor:'lightyellow',
            alignItems: "center",
            paddingLeft: "1vw",
          }}
        >
          <h2>{<strong>Refunded:</strong>}</h2>
          <h2>{<strong>{props.totalRefunded}</strong>}</h2>
        </div>
      </div>
    </>
  );
};

export const SalesWidget = (props) => {
  return (
    <>
      <div
        style={{
          width: "20%",
          display: "flex",
          flexDirection: "column",
          width: "22%",
          height: "80%",
          backgroundColor: "white",
          marginLeft: "2vw",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "25%",
            // backgroundColor:'green',
            alignItems: "center",
            marginBottom: "1vh",
            paddingLeft: "1vw",
          }}
        >
          <h1>{<strong>Sales:</strong>}</h1>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "20%",
            // backgroundColor:'lightblue',
            alignItems: "center",
            marginBottom: "1vh",
            paddingLeft: "1vw",
          }}
        >
          <h2>{<strong>Today:</strong>}</h2>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "20%",
            // backgroundColor:'lightcoral',
            alignItems: "center",
            marginBottom: "1vh",
            paddingLeft: "1vw",
          }}
        >
          <h2>{<strong>This Month:</strong>}</h2>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "20%",
            // backgroundColor:'lightyellow',
            alignItems: "center",
            paddingLeft: "1vw",
          }}
        >
          <h2>{<strong>This Year:</strong>}</h2>
        </div>
      </div>
    </>
  );
};

export const TrafficWidget = (props) => {
  return (
    <>
      <div
        style={{
          width: "20%",
          display: "flex",
          flexDirection: "column",
          width: "22%",
          height: "80%",
          backgroundColor: "white",
          marginLeft: "2vw",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "25%",
            // backgroundColor:'green',
            alignItems: "center",
            marginBottom: "1vh",
            paddingLeft: "1vw",
          }}
        >
          <h1>{<strong>Visitors:</strong>}</h1>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "20%",
            // backgroundColor:'lightblue',
            alignItems: "center",
            marginBottom: "1vh",
            paddingLeft: "1vw",
          }}
        >
          <h2>{<strong>Today:</strong>}</h2>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "20%",
            // backgroundColor:'lightcoral',
            alignItems: "center",
            marginBottom: "1vh",
            paddingLeft: "1vw",
          }}
        >
          <h2>{<strong>This Month:</strong>}</h2>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "20%",
            // backgroundColor:'lightyellow',
            alignItems: "center",
            paddingLeft: "1vw",
          }}
        >
          <h2>{<strong>This Year:</strong>}</h2>
        </div>
      </div>
    </>
  );
};
