import React from "react";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { ServicesText } from "./servicesText.js";
import "./styles.css";

export const Services = () => {
  return (
    <>
      <Header />
      <div className="servicesmain">
        <ServicesText />
      </div>
      <Footer />
    </>
  );
};
