import React, { useEffect, useState, useContext } from "react";
import { DataContext } from "../../common/DataContext";
import { AuthContext } from "../../common/AuthContext";
import {
  AddProductModal,
  ProductWidget,
  ImagePreviewModal,
  EditProductModal,
} from "./adminProductComponents";
import useToggle from "../../hooks/useToggle";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { useNavigate } from "react-router-dom";
import { ConfirmModal, Sidebar, StyledButton } from "../../components/common";

export const AdminProductsPage = () => {
  const [addModalOpen, toggleAddModalOpen] = useToggle(false);
  const [editModalOpen, toggleEditModalOpen] = useToggle(false);
  const [previewModalOpen, togglePreviewModalOpen] = useToggle(false);
  const [confirmModalOpen, toggleConfirmwModalOpen] = useToggle(false);
  const [newVariantName, setNewVariantName] = useState(null);
  const [newProductName, setNewProductName] = useState(null);
  const [newProductDesc, setNewProductDesc] = useState(null);
  const [newProductMaterial, setNewProductMaterial] = useState(null);

  const [newProductColor, setNewProductColor] = useState(null);

  const [newProductHeight, setNewProductHeight] = useState(0.0);
  const [newProductLength, setNewProductLength] = useState(0.0);
  const [newProductWidth, setNewProductWidth] = useState(0.0);

  const [newProductPrice, setNewProductPrice] = useState(0.0);
  const [newProductWeight, setNewProductWeight] = useState(0.0);
  const [newProductQuantity, setNewProductQuantity] = useState(0);
  const [newProductCategory, setNewProductCategory] = useState(null);
  const [newProductSubCategory, setNewProductSubCategory] = useState(null);
  const [selectedProductID, setSelectedProductID] = useState(null);
  const [previewImages, setPreviewImages] = useState(null);
  const [variants, setVariants] = useState([]);
  const {
    setSelectedRow,
    createProduct,
    newProductImages,
    setNewProductImages,
    handleSetNewProductImages,
    handleAddNewProductImages,
    fetchAdminProducts,
    currentProducts,
    currentCategories,
    deleteProduct,
    tempIndex,
    handleNavigate,
    editProduct,
    selectedProductImages,
    setSelectedProductImages,
    setCurrentImageURLs,
    fetchCategories,
    fetchProducts,
    sendTestEmail,
    shareTargetProduct,
    deleteVariant
  } = useContext(DataContext);
  const { user } = useContext(AuthContext);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryName, setSelectedCategoryName] = useState(null);
  const [tempVariant, setTempVariant] = useState(null);
  const [tempVariantIndex, setTempVariantIndex] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (user === null) {
      handleNavigate("/login");
    }
    if (user && user.role != "Admin") {
      handleNavigate("/login");
    } else if (user && user.role == "Admin") {
      setSelectedRow("Products");
      handleClearForm();
      fetchAdminProducts();
      fetchCategories('admin');
    }
  }, [user]);

  // useEffect(() => {

  // }, []);
  const handleAddVariant = (
    selectedVariantName,
    selectedVariantColor,
    selectedVariantMaterial,
    selectedVariantLength,
    selectedVariantWidth,
    selectedVariantHeight,
    selectedVariantPrice,
    selectedVariantWeight,
    selectedVariantQuantity
  ) => {
    // Define the required fields based on the arguments
    const requiredFields = {
      name: selectedVariantName,
      color: selectedVariantColor,
      material: selectedVariantMaterial,
      length: selectedVariantLength,
      width: selectedVariantWidth,
      height: selectedVariantHeight,
      price: selectedVariantPrice,
      weight: selectedVariantWeight,
      quantity: selectedVariantQuantity,
    };
    console.log(    
      selectedVariantName,
      selectedVariantColor,
      selectedVariantMaterial,
      selectedVariantLength,
      selectedVariantWidth,
      selectedVariantHeight,
      selectedVariantPrice,
      selectedVariantWeight,
      selectedVariantQuantity
    )
    // Check for any missing required fields
    const missingFields = Object.keys(requiredFields).filter(
      (key) => !requiredFields[key]
    );
  
    if (missingFields.length > 0) {
      console.log(missingFields)
      alert(`Please fill in the following fields: ${missingFields.join(", ")}`);
    } else {
      // Check if the new variant already exists in the variant list
      const isVariantExist = variants.some(variant => 
        variant.name === selectedVariantName &&
        variant.color === selectedVariantColor &&
        variant.material === selectedVariantMaterial &&
        variant.length === selectedVariantLength &&
        variant.width === selectedVariantWidth &&
        variant.height === selectedVariantHeight &&
        variant.price === selectedVariantPrice &&
        variant.weight === selectedVariantWeight &&
        variant.quantity === selectedVariantQuantity
      );
  
      if (isVariantExist) {
        alert("Variant already exists!");
      } else {
        // If variant doesn't exist, add it to the list
        let variantList = [...variants];
        let newVariant = {
          name: selectedVariantName,
          color: selectedVariantColor,
          material: selectedVariantMaterial,
          length: selectedVariantLength,
          width: selectedVariantWidth,
          height: selectedVariantHeight,
          price: selectedVariantPrice,
          weight: selectedVariantWeight,
          quantity: selectedVariantQuantity,
        };
        variantList.push(newVariant);
        setVariants(variantList);
        // handleClearForm(); // Uncomment this if you want to clear the form after adding the variant
      }
    }
  };
  
  const handleDeleteVariant = (index) => {
    let variantList = [...variants];
    let targetVariant=variantList[index]
    deleteVariant(targetVariant.id)
    variantList.splice(index, 1); 
    setVariants(variantList);

  }

  const handleEditVariant = (
    index,

    name,
    color,
    material,
    length,
    width,
    height,
    price,
    weight,
    quantity,
  ) => {
    let args = [
      name,
      color,
      material,
      length,
      width,
      height,
      price,
      weight,
      quantity,
    ];
    let keys = [
      "name",
      "color",
      "material",
      "length",
      "width",
      "height",
      "price",
      "weight",
      "quantity",
    ];
    let variantList = [...variants];
    let selectedVariant = variantList[index];
    keys.forEach((key, i) => {
      if (args[i] !== null) {
        selectedVariant[key] = args[i];
      }
    });
    variantList[index] = selectedVariant;
    setVariants(variantList);
  };

  const handleSetSelectedProductID = (
    e,
    id,
    name,
    desc,
    color,
    material,
    price,
    ship,
    quantity,
    category,
    subcategory,
    images,
    variants,
  ) => {
    e.stopPropagation();
    if (id === selectedProductID) {
      handleClearForm();
    } else {
      setSelectedProductID(id);
      setNewProductName(name);
      setNewProductDesc(desc);
      setNewProductMaterial(material);
      setNewProductColor(color);
      setNewProductPrice(price);
      setNewProductWeight(ship);
      setNewProductQuantity(quantity);
      setNewProductCategory(category);
      setNewProductSubCategory(subcategory);
      setSelectedProductImages(images);
      setVariants(variants);
    }
  };

  const handleDeleteProduct = () => {
    deleteProduct(selectedProductID);
    handleToggleConfirmModalOpen();
    handleClearForm();
  };

  const handleSetNewVariantName = (e) => {
    setNewVariantName(e.target.value);
  };

  const handleSetNewProductName = (e) => {
    setNewProductName(e.target.value);
  };

  const handleSetNewProductDesc = (e) => {
    setNewProductDesc(e.target.value);
  };

  const handleSetNewProductMaterial = (e) => {
    setNewProductMaterial(e.target.value);
  };

  const handleSetNewProductColor = (e) => {
    setNewProductColor(e.target.value);
  };

  const handleSetNewProductLength = (e) => {
    setNewProductLength(e.target.value);
  };

  const handleSetNewProductWidth = (e) => {
    setNewProductWidth(e.target.value);
  };

  const handleSetNewProductHeight = (e) => {
    setNewProductHeight(e.target.value);
  };

  const handleSetNewProductPrice = (e) => {
    setNewProductPrice(e.target.value);
  };
  const handleSetNewProductWeight = (e) => {
    setNewProductWeight(e.target.value);
  };
  const handleSetNewProductQuantity = (e) => {
    setNewProductQuantity(e.target.value);
  };
  const handleSetNewProductCategory = (e) => {
    setNewProductCategory(e.target.value);
  };

  const handleAddProductModalOpen = () => {
    toggleAddModalOpen();
  };

  const handleEditProductModalOpen = () => {
    if (editModalOpen) {
      handleClearForm();
    }
    toggleEditModalOpen();
  };

  const handleTogglePreviewModalOpen = (e, images = null) => {
    e.stopPropagation();

    togglePreviewModalOpen();
    if (images !== null) {
      handleSetPreviewImages(images);
    }
  };

  const handleToggleConfirmModalOpen = () => {
    toggleConfirmwModalOpen();
  };

  const handleSetPreviewImages = (images) => {
    setPreviewImages(images);
  };

  const handleDeleteSelectedProductImage = (deleteIndex) => {
    const updatedItems = selectedProductImages.filter(
      (_, index) => index !== deleteIndex,
    );
    setSelectedProductImages(updatedItems);
  };

  const handleCreateProduct = (selectedCategory, selectedSubcategory) => {
    createProduct(
      newProductName,
      newProductDesc,
      newProductMaterial,
      newProductLength,
      newProductWidth,
      newProductHeight,
      newProductColor,
      newProductPrice,
      newProductWeight,
      newProductQuantity,
      selectedCategory,
      selectedSubcategory,
      variants,
    );
    handleAddProductModalOpen();
  };

  const handleClearForm = () => {
    setSelectedProductID(null);
    setNewProductName(null);
    setNewProductDesc(null);
    setNewProductMaterial(null);
    setNewProductColor(null);
    setNewProductLength(null);
    setNewProductWidth(null);
    setNewProductHeight(null);
    setNewProductPrice(null);
    setNewProductWeight(null);
    setNewProductQuantity(null);
    setNewProductImages(null);
    setCurrentImageURLs(null);
  };

  const handleCancelProduct = () => {
    handleClearForm();
    toggleAddModalOpen();
  };

  const handleEditProduct = (category, subcategory) => {
    editProduct(
      selectedProductID,
      newProductName,
      newProductDesc,
      newProductMaterial,
      newProductLength,
      newProductWidth,
      newProductHeight,
      newProductColor,
      newProductPrice,
      newProductWeight,
      newProductQuantity,
      category,
      subcategory,
      variants,
    );

    handleEditProductModalOpen();
  };

  const getSubcategoryName = (categoryId, subcategoryId) => {
    const category = currentCategories?.find(
      (cat) => cat.category_id === categoryId,
    );
    if (category) {
      const subcategory = category.subcategories?.find(
        (subcat) => subcat.id === subcategoryId,
      );
      return subcategory ? subcategory.name : "Miscellaneous";
    }
    return "Miscellaneous";
  };

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "row",
        backgroundColor: "rgb(78, 171, 218)",
      }}
    >
      <ConfirmModal
        question={"Are you sure you want to delete this product?"}
        modalHandler={handleToggleConfirmModalOpen}
        modalState={confirmModalOpen}
        confirmAction={handleDeleteProduct}
      />
      <Sidebar />
      <div
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "rgb(78, 171, 218)",
        }}
      >
        <ImagePreviewModal
          modalHandler={handleTogglePreviewModalOpen}
          modalState={previewModalOpen}
          previewImages={previewImages}
        />
        <AddProductModal
          modal_open={addModalOpen}
          modal_handler={handleAddProductModalOpen}
          title={"Add Product"}
          newProductName={newProductName}
          handleSetNewProductName={handleSetNewProductName}
          newProductDesc={newProductDesc}
          handleSetNewProductDesc={handleSetNewProductDesc}
          newProductMaterial={newProductMaterial}
          handleSetNewProductMaterial={handleSetNewProductMaterial}
          newProductColor={newProductColor}
          handleSetNewProductColor={handleSetNewProductColor}
          handleSetNewProductLength={handleSetNewProductLength}
          handleSetNewProductWidth={handleSetNewProductWidth}
          handleSetNewProductHeight={handleSetNewProductHeight}
          newProductLength={newProductLength}
          newProductWidth={newProductWidth}
          newProductHeight={newProductHeight}
          newProductWeight={newProductWeight}
          handleSetNewProductWeight={handleSetNewProductWeight}
          newProductPrice={newProductPrice}
          handleSetNewProductPrice={handleSetNewProductPrice}
          newProductQuantity={newProductQuantity}
          handleSetNewProductQuantity={handleSetNewProductQuantity}
          newProductCategory={newProductCategory}
          handleSetNewProductCategory={handleSetNewProductCategory}
          newProductImages={newProductImages}
          handleSetNewProductImages={handleSetNewProductImages}
          handleCreateProduct={handleCreateProduct}
          currentCategories={currentCategories}
          handleCancelProduct={handleCancelProduct}
          handleAddVariant={handleAddVariant}
          handleSetNewVariantName={handleSetNewVariantName}
          variants={variants}
        />
        <EditProductModal
          modal_open={editModalOpen}
          modal_handler={handleEditProductModalOpen}
          title={"Edit Product"}
          newProductName={newProductName}
          handleSetNewProductName={handleSetNewProductName}
          newProductDesc={newProductDesc}
          handleSetNewProductDesc={handleSetNewProductDesc}
          newProductMaterial={newProductMaterial}
          handleSetNewProductMaterial={handleSetNewProductMaterial}
          newProductColor={newProductColor}
          newProductWeight={newProductWeight}
          handleSetNewProductWeight={handleSetNewProductWeight}
          newProductPrice={newProductPrice}
          handleSetNewProductPrice={handleSetNewProductPrice}
          newProductQuantity={newProductQuantity}
          handleSetNewProductQuantity={handleSetNewProductQuantity}
          newProductCategory={newProductCategory}
          handleSetNewProductCategory={handleSetNewProductCategory}
          handleAddNewProductImages={handleAddNewProductImages}
          handleDeleteSelectedProductImage={handleDeleteSelectedProductImage}
          handleEditProduct={handleEditProduct}
          handleEditVariant={handleEditVariant}
          handleDeleteVariant={handleDeleteVariant}
          handleAddVariant={handleAddVariant}
          handleSetNewProductColor={handleSetNewProductColor}
          handleSetNewProductLength={handleSetNewProductLength}
          handleSetNewProductWidth={handleSetNewProductWidth}
          handleSetNewProductHeight={handleSetNewProductHeight}
          currentCategories={currentCategories}
          variants={variants}
        />
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              height: "10%",
              backgroundColor: "ghostwhite",
              boxShadow: "1px 1px 6px 2px black",
              alignItems: "center",
              justifyContent: "right",
            }}
          >

            <StyledButton
              buttonText={"TEST EMAIL"}
              buttonAction={sendTestEmail}
            />
            <StyledButton
              buttonText={"Add"}
              buttonAction={handleAddProductModalOpen}
            />
            <StyledButton
              buttonText={"Edit"}
              buttonAction={handleEditProductModalOpen}
            />
            <StyledButton
              buttonText={"Share"}
              buttonAction={() => shareTargetProduct(selectedProductID)}
            />
            <StyledButton
              buttonAction={() => handleToggleConfirmModalOpen()}
              buttonText={"Delete"}
            />
          </div>
        </>
        {/* Content section */}
        <div
          style={{
            flex: 1,
            overflowY: "auto",
            overflowX: "hidden",
            marginTop: "1vh", // Adjust to header height
            marginBottom: "1vh", // Adjust to footer height
          }}
        >
          {/* Display products based on selected category */}
          {currentCategories &&
            currentCategories?.map((category, index) => {
              const categoryProducts = currentProducts?.filter(
                (product) => product.product_category === category.category_id,
              );

              return (
                <div key={index} style={{ marginBottom: "30px" }}>
                  {/* Category label */}
                  <h2 style={{ textAlign: "center", marginLeft: "15px" }}>
                    {category.category_name}
                  </h2>

                  {/* Check if category has products */}
                  {categoryProducts?.length > 0 ? (
                    Object.entries(
                      categoryProducts.reduce((acc, product) => {
                        const subcategoryName = getSubcategoryName(
                          product.product_category,
                          product.product_subcategory,
                        );
                        if (!acc[subcategoryName]) {
                          acc[subcategoryName] = [];
                        }
                        acc[subcategoryName].push(product);
                        return acc;
                      }, {}),
                    ).map(
                      ([subcategoryName, subcategoryProducts], subIndex) => (
                        <div key={subIndex} style={{ marginBottom: "20px" }}>
                          {/* Subcategory label */}
                          <h3
                            style={{
                              textAlign: "center",
                              marginLeft: "20px",
                              fontSize: "1.2rem",
                              fontWeight: 600,
                            }}
                          >
                            {subcategoryName}
                          </h3>

                          {/* Products grid for subcategory */}
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "repeat(4, 1fr)",
                              gap: "15px",
                              padding: "10px 15px",
                              justifyItems: "center",
                              boxSizing: "border-box",
                            }}
                          >
                            {subcategoryProducts.map((product) => (
                              <div
                                key={product.product_id}
                                style={{
                                  backgroundColor:
                                    selectedProductID === product.product_id
                                      ? "#87CEFA"
                                      : "white",
                                  padding: "10px",
                                  borderRadius: "8px",
                                  textAlign: "center",
                                  maxWidth: "200px",
                                  boxSizing: "border-box",
                                }}
                                onClick={(e) =>
                                  handleSetSelectedProductID(
                                    e,
                                    product.product_id,
                                    product.product_name,
                                    product.product_desc,
                                    product.product_color,
                                    product.product_material,
                                    product.product_price,
                                    product.product_weight,
                                    product.product_quantity,
                                    product.product_category,
                                    product.product_subcategory,
                                    product.images,
                                    product.product_variants,
                                  )
                                }
                              >
                                <img
                                  src={product.images[0]?.thumbnail_url}
                                  alt={product.product_name}
                                  style={{
                                    width: "100%",
                                    height: "180px",
                                    objectFit: "cover",
                                    borderRadius: "8px",
                                  }}
                                />
                                <h2 style={{ margin: "10px 0" }}>
                                  {product.product_name}
                                </h2>
                                <p style={{ margin: "5px 0" }}>
                                  In Stock:{product.product_quantity}
                                </p>
                                <p style={{ margin: "5px 0" }}>
                                  ${product.product_price.toFixed(2)}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      ),
                    )
                  ) : (
                    // No products in this category
                    <div style={{ marginLeft: "20px" }}>
                      <p style={{ fontStyle: "italic", textAlign: "center" }}>
                        New products coming soon...
                      </p>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
