import React, { useState, useContext, useEffect } from "react";
import { DataContext } from "../../common/DataContext";

export const ContactText = () => {
  const { fetchContactInfo, mobile, address, phone, email } =
    useContext(DataContext);

  useEffect(() => {
    fetchContactInfo();
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "top",
          height: "auto",
        }}
      >
        <h1 style={{ textAlign: "center", color: "black" }}>Contact</h1>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "auto",
            width: "90%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <h2
              style={{
                width: "40%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                // marginRight: "2vw",
              }}
            >
              <strong>Email:</strong>
            </h2>

            <label
              style={{
                color: "black",
                fontSize: "1.25rem",
                fontWeight: 600,
              }}
            >
              {"statelystickers247@gmail.com"}
            </label>
          </div>
        </div>
      </div>
    </>
  );
};
