import React, { useEffect, useContext, useState } from "react";
import useToggle from "../../hooks/useToggle";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { DataContext } from "../../common/DataContext";
import { useNavigate, useParams } from "react-router-dom";
import { ShoppingCartModal } from "../../components/common";
import {
  AddProductModal,
  ProductWidget,
  ImagePreviewModal,
  EditProductModal,
} from "../AdminProducts/adminProductComponents";

const MobileSubcategoryRow = (props) => {
  const { mobile } = useContext(DataContext);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSetIndex = (operator) => {
    let temp_index = currentIndex;
    if (operator === "+") {
      if (temp_index + 1 <= props.subcategoryProducts.length) {
        temp_index += 1;
      }
    }
    if (operator === "-") {
      if (temp_index - 1 >= 0) {
        temp_index -= 1;
      }
    }

    // console.log(temp_index);
    setCurrentIndex(temp_index);
  };
  return (
    <>
      <div
        // key={subIndex}
        style={{
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h3
          style={{
            textAlign: "center",

            fontSize: "1.2rem",
            fontWeight: 600,
            marginTop: mobile ? "1vh" : "",
            marginBottom: mobile ? "1vh" : "",
          }}
        >
          {props.subcategoryName}
        </h3>
        <div
          // key={rowIndex}
          style={{
            display: "flex",
            flexDirection: "row",
            gap: mobile ? "3vw" : "5vw",
            alignItems: "center",
            justifyContent: mobile ? "center" : "center",
            width: mobile ? "90%" : "100%",
            height: "38vh",
            overflowX: mobile ? "" : "auto",
            paddingTop: "1vh",
            paddingBottom: "1vh",
            marginBottom: "5vh",
            // backgroundColor: "red",
          }}
        >
          <button
            onClick={() => handleSetIndex("-")}
            style={{
              transform: "translateY(-50%)",
              zIndex: 1,
              backgroundColor: "white",
              border: "none",
              borderRadius: "50%",
              boxShadow: "1px 1px 6px 1px black",
              width: "40px",
              height: "40px",
              cursor: "pointer",
            }}
            disabled={currentIndex === 0}
          >
            ◀
          </button>
          {props.subcategoryProducts[currentIndex] &&
          props.subcategoryProducts[currentIndex] !== null ? (
            <>
              <div
                key={props.subcategoryProducts[currentIndex].product_id}
                style={{
                  backgroundColor: "white",
                  boxShadow: "1px 1px 6px 2px black",
                  padding: "10px",
                  borderRadius: "8px",
                  textAlign: "center",
                  flex: "0 0 auto",
                  width: mobile ? "56vw" : "15vw",
                  height: mobile ? "38vh" : "35vh",

                  boxSizing: "border-box",
                  scrollSnapAlign: mobile ? "center" : "",
                  scrollSnapStop: mobile ? "always" : "",
                }}
                onClick={(e) =>
                  props.handleSetSelectedProductID(
                    props.subcategoryProducts[currentIndex].product_id,
                    props.subcategoryProducts[currentIndex].product_name,
                    props.subcategoryProducts[currentIndex].product_desc,
                    props.subcategoryProducts[currentIndex].product_color,
                    props.subcategoryProducts[currentIndex].product_material,
                    props.subcategoryProducts[currentIndex].product_price,
                    props.subcategoryProducts[currentIndex].product_weight,
                    props.subcategoryProducts[currentIndex].product_height,
                    props.subcategoryProducts[currentIndex].product_length,
                    props.subcategoryProducts[currentIndex].product_width,
                    props.subcategoryProducts[currentIndex].product_quantity,
                    props.subcategoryProducts[currentIndex].product_category,
                    props.subcategoryProducts[currentIndex].images,
                    props.subcategoryProducts[currentIndex].product_variants,
                  )
                }
              >
                <img
                  src={
                    props.subcategoryProducts[currentIndex].images &&
                    props.subcategoryProducts[currentIndex].images[0]
                      ?.thumbnail_url
                  }
                  alt={props.subcategoryProducts[currentIndex].product_name}
                  style={{
                    height: "auto",
                    maxHeight: "20vh",
                    width: "auto",
                    maxWidth: "90%",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                />
                <h2
                  style={{
                    margin: "10px 0",
                    fontSize: mobile ? "1rem" : "1.2rem",
                  }}
                >
                  {props.subcategoryProducts[currentIndex].product_name}
                </h2>
                <p
                  style={{
                    margin: "5px 0",
                    fontSize: mobile ? ".8rem" : "1rem",
                  }}
                >
                  $
                  {props.subcategoryProducts[
                    currentIndex
                  ].product_price.toFixed(2)}
                </p>
              </div>
            </>
          ) : null}

          <button
            onClick={() => handleSetIndex("+")}
            style={{
              transform: "translateY(-50%)",
              zIndex: 1,
              backgroundColor: "white",
              border: "none",
              borderRadius: "50%",
              boxShadow: "1px 1px 6px 2px black",
              width: "40px",
              height: "40px",
              cursor: "pointer",
            }}
            disabled={currentIndex === props.subcategoryProducts.length - 1}
          >
            ▶
          </button>
        </div>
      </div>
    </>
  );
};

export const Shop = () => {
  const {
    fetchProducts,
    currentCategories,
    currentProducts,
    mobile,
    cartOpen,
  } = useContext(DataContext);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [previewImages, setPreviewImages] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState();
  const [selectedProductName, setSelectedProductName] = useState();
  const [selectedProductDesc, setSelectedProductDesc] = useState();
  const [selectedProductColor, setSelectedProductColor] = useState();
  const [selectedProductMaterial, setSelectedProductMarterial] = useState();
  const [selectedProductPrice, setSelectedProductPrice] = useState();
  const [selectedProductWeight, setSelectedProductWeight] = useState();
  const [selectedProductQuantity, setSelectedProductQuantity] = useState();
  const [selectedProductCategory, setSelectedProductCategory] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedProductLength, setSelectedProductLength] = useState();
  const [selectedProductWidth, setSelectedProductWidth] = useState();
  const [selectedProductHeight, setSelectedProductHeight] = useState();
  const [selectedProductVariants, setSelectedProductVariants] = useState();
  const [selectedProductSubCategory, setSelectedProductSubCategory] =
    useState();
  const [selectedProductImages, setSelectedProductImages] = useState();
  const [previewModalOpen, togglePreviewModalOpen] = useToggle(false);
  const [productModalOpen, toggleProductModalOpen] = useToggle(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [selectedCategoryName, setSelectedCategoryName] = useState(null);
  const [publicCategory, togglePublicCategory] = useToggle(true);
  const [selectedCategoryDescription, setSelectedCategoryDescription] =
    useState(null); // New state for category description
  const { categoryName } = useParams(); // Capture the category name from the URL

  // Fetch all products on initial load
  useEffect(() => {
    fetchProducts();
  }, []);

  // Match the category from the URL and set the selected category
  useEffect(() => {
    let currentUrl = window.location.href;
    let lastSegment = currentUrl.split("/").pop();
    let formattedSegment = lastSegment.replace(/-/g, " ");
    const matchedCategory = currentCategories?.find(
      (category) =>
        category.category_name.toLowerCase() === formattedSegment.toLowerCase()
    );
    if (matchedCategory) {
      setSelectedCategory(matchedCategory.category_id);
      setSelectedCategoryName(matchedCategory.category_name);
      setSelectedCategoryDescription(matchedCategory.category_desc); // Set category description
      togglePublicCategory(false)
    } else {
      setSelectedCategory(null);
      setSelectedCategoryName("");
      setSelectedCategoryDescription(""); // Reset description if no match
    }
  }, [currentCategories, selectedCategory]);
  
  // Fetch products whenever the selected category changes
  useEffect(() => {
    if (currentCategories?.length) {
      if (categoryName) {
        const formattedCategory = categoryName.replace(/-/g, " ");
        const matchedCategory = currentCategories.find(
          (category) =>
            category.category_name.toLowerCase() === formattedCategory.toLowerCase()
        );
  
        if (matchedCategory) {
          setSelectedCategory(matchedCategory.category_id);
          setSelectedCategoryName(matchedCategory.category_name);
          setSelectedCategoryDescription(matchedCategory.category_desc); // Set category description
          fetchProducts(matchedCategory.category_id); // Fetch products for the selected category
        } else {
          setSelectedCategory(null);
          setSelectedCategoryName("");
          setSelectedCategoryDescription(""); // Reset description if no match
          fetchProducts(); // Fetch all products
        }
      } else {
        togglePublicCategory(true)
        setSelectedCategory(null);
        setSelectedCategoryName("");
        setSelectedCategoryDescription(""); // Reset description if no match
        fetchProducts(); // Fetch all products
      }
    }
  }, [categoryName, currentCategories, selectedCategory]);

  // When the selectedCategory changes, fetch the products
  useEffect(() => {
    if (selectedCategory) {
      // Fetch products for the selected category
      fetchProducts(selectedCategory);
    }
  }, [selectedCategory]);

  const handleSetSelectedProductID = (
    product_id,
    product_name,
    product_desc,
    product_color,
    product_material,
    product_price,
    product_weight,
    product_height,
    product_length,
    product_width,
    product_quantity,
    product_category,
    images,
    variants,
  ) => {
    if (product_id === selectedProductId) {
      handleUnselectProduct();
      handleToggleProductModal(false);
    } else {
      setSelectedProductId(product_id);
      setSelectedProductName(product_name);
      setSelectedProductDesc(product_desc);
      setSelectedProductColor(product_color);
      setSelectedProductMarterial(product_material);
      setSelectedProductPrice(product_price);
      setSelectedProductWeight(product_weight);
      setSelectedProductHeight(product_height);
      setSelectedProductLength(product_length);
      setSelectedProductWidth(product_width);
      setSelectedProductQuantity(product_quantity);
      setSelectedProductCategory(product_category);
      setSelectedProductImages(images);
      setSelectedProductVariants(variants);
      handleToggleProductModal(true);
    }
  };

  const handleUnselectProduct = () => {
    setSelectedProductId(null);
    setSelectedProductName(null);
    setSelectedProductDesc(null);
    setSelectedProductColor(null);
    setSelectedProductMarterial(null);
    setSelectedProductPrice(null);
    setSelectedProductWeight(null);
    setSelectedProductQuantity(null);
    setSelectedProductCategory(null);
    setSelectedProductImages(null);
    setSelectedProductHeight(null);
    setSelectedProductWeight(null);
    setSelectedProductLength(null);
  };

  const handleToggleProductModal = (e) => {
    if (e) {
      toggleProductModalOpen(e);
    } else {
      toggleProductModalOpen();
    }
  };

  const handleTogglePreviewModalOpen = (e, images = null) => {
    e.stopPropagation();

    togglePreviewModalOpen();
    if (images !== null) {
      handleSetPreviewImages(images);
    }
  };

  const handleSetPreviewImages = (images) => {
    setPreviewImages(images);
  };
  // Helper function to get the subcategory name from the ID
  const getSubcategoryName = (categoryId, subcategoryId) => {
    const category = currentCategories?.find(
      (cat) => cat.category_id === categoryId,
    );
    if (category) {
      const subcategory = category.subcategories?.find(
        (subcat) => subcat.id === subcategoryId,
      );
      return subcategory ? subcategory.name : "Miscellaneous";
    }
    return "Miscellaneous";
  };
  return (
    <>
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "rgb(78, 171, 218)",
        }}
      >
        {/* Fixed Header */}
        <div
          style={{
            position: "fixed",
            top: 0,
            width: "100%",
            zIndex: 1000, // Ensure header stays on top
          }}
        >
          <Header />
        </div>
        <ShoppingCartModal />
        {productModalOpen &&
        productModalOpen === true &&
        selectedProductId !== null ? (
          <>
            <ProductWidget
              mode="store"
              product_id={selectedProductId}
              product_name={selectedProductName}
              product_desc={selectedProductDesc}
              product_color={selectedProductColor}
              product_material={selectedProductMaterial}
              product_price={selectedProductPrice}
              product_weight={selectedProductWeight}
              product_height={selectedProductHeight}
              product_length={selectedProductLength}
              product_width={selectedProductWidth}
              product_quantity={selectedProductQuantity}
              product_category={selectedProductCategory}
              images={selectedProductImages}
              imageIndex={imageIndex}
              productModalOpen={productModalOpen}
              toggleProductModalOpen={toggleProductModalOpen}
              handleToggleProductModal={handleToggleProductModal}
              handleTogglePreviewModalOpen={handleTogglePreviewModalOpen}
              previewModalOpen={previewModalOpen}
              previewImages={previewImages}
              setImageIndex={setImageIndex}
              handleUnselectProduct={handleUnselectProduct}
              handleSetSelectedProductID={handleSetSelectedProductID}
              variants={selectedProductVariants}
            />
          </>
        ) : (
          <></>
        )}

        {/* Content section */}
        <div
          style={{
            flex: 1,
            overflowY: "auto",
            overflowX: "hidden", // Prevent horizontal scrolling
            marginTop: mobile ? "30px" : "40px", // Adjust to header height
            marginBottom: "60px", // Adjust to footer height
          }}
        >
          {/* SHOP banner */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "black",
              alignItems: "center",
              color: "white",
              textAlign: "center",
              paddingLeft: "1vw",
              paddingRight: "1vw",
              paddingTop: mobile ? "1vh" : "20px",
              paddingBottom: mobile ? "1vh" : "20px",
              marginTop: "10px",
              width: "100%",
            }}
          >
            <h1
              style={{
                margin: "0",
                fontSize: mobile ? "1.25rem" : "2rem",
                textAlign: "center",
              }}
            >
              {selectedCategoryName
                ? `Shop ${selectedCategoryName}`
                : "SHOP ALL"}
            </h1>

            <p
              style={{
                textAlign: "center",
                width: "96%",
                margin: "0",
                fontSize: mobile ? ".7rem" : "1.2rem",
              }}
            >
              {selectedCategoryDescription
                ? selectedCategoryDescription
                : "Shop all product categories"}
            </p>
          </div>

          {/* Conditionally render categories based on selection  */}
          {selectedCategory
            ? currentCategories?.map((category, index) => {
                if (category.category_id === selectedCategory) {
                  const categoryProducts = currentProducts?.filter(
                    (product) =>
                      product.product_category === category.category_id,
                  );

                  return (
                    <div key={index} style={{ marginBottom: "30px" }}>
                      {/* Category label */}
                      <h2
                        style={{
                          textAlign: "center",
                          marginTop: mobile ? "0vh" : "",
                          marginBottom: mobile ? ".25vh" : "",
                        }}
                      >
                        {category.category_name }
                      </h2>
                      {mobile ? (
                        <>
                          {categoryProducts?.length > 0 ? (
                            Object.entries(
                              categoryProducts.reduce((acc, product) => {
                                const subcategoryName = getSubcategoryName(
                                  product.product_category,
                                  product.product_subcategory,
                                );
                                if (!acc[subcategoryName]) {
                                  acc[subcategoryName] = [];
                                }
                                acc[subcategoryName].push(product);
                                return acc;
                              }, {}),
                            ).map(
                              (
                                [subcategoryName, subcategoryProducts],
                                subIndex,
                              ) => (
                                <>
                                  <MobileSubcategoryRow
                                    subcategoryName={subcategoryName}
                                    subcategoryProducts={subcategoryProducts}
                                    handleSetSelectedProductID={handleSetSelectedProductID}
                                  />
                                </>
                              ),
                            )
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <>
                        
                        {
                          categoryProducts?.length > 0
                            ? Object.entries(
                                categoryProducts.reduce((acc, product) => {
                                  const subcategoryName = getSubcategoryName(
                                    product.product_category,
                                    product.product_subcategory,
                                  );
                                  if (!acc[subcategoryName]) {
                                    acc[subcategoryName] = [];
                                  }
                                  acc[subcategoryName].push(product);
                                  return acc;
                                }, {})
                                
                              )
                              .sort(([a], [b]) => a.localeCompare(b)) 
                              .map(
                                (
                                  [subcategoryName, subcategoryProducts],
                                  subIndex,
                                ) => (
                                  <div
                                    key={subIndex}
                                    style={{
                                      marginBottom: "20px",
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >

                                    <h3
                                      style={{
                                        textAlign: "center",
                                        fontSize: "1.2rem",
                                        fontWeight: 600,
                                      }}
                                    >
                                      <strong>{subcategoryName}</strong>
                                    </h3>

                                    {subcategoryProducts
                                      .reduce((rows, product, index) => {
                                        if (index % 4 === 0) rows.push([]); // Start a new row
                                        rows[rows.length - 1].push(product); // Add product to the last row
                                        return rows;
                                      }, [])
                                      .sort(([a], [b]) => a.localeCompare(b)) 
                                      .map((rowProducts, rowIndex) => (
                                        <div
                                          key={rowIndex}
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: mobile ? "" : "5vw",
                                            alignItems: "center",
                                            justifyContent: mobile
                                              ? ""
                                              : "center",
                                            width: mobile ? "58vw" : "100%",
                                            height: "38vh",
                                            overflowX: mobile ? "auto" : "auto",
                                            paddingTop: "1vh",
                                            paddingBottom: "1vh",
                                            marginBottom: "5vh",
                                            flex: "0 0 auto",
                                            paddingRight: "1vw",
                                            // backgroundColor:'red',
                                            scrollSnapType: mobile
                                              ? "x mandatory"
                                              : "",
                                          }}
                                        >
                                          {rowProducts.map((product) => (
                                            <div
                                              key={product.product_id}
                                              style={{
                                                backgroundColor: "white",
                                                boxShadow:
                                                  "1px 1px 6px 2px black",
                                                padding: "10px",
                                                borderRadius: "8px",
                                                textAlign: "center",
                                                flex: "0 0 auto",
                                                width: mobile ? "56vw" : "15vw",
                                                height: mobile
                                                  ? "38vh"
                                                  : "35vh",
                                                margin: mobile ? "0 20vw" : "",
                                                marginnRight: "20vw",
                                                boxSizing: "border-box",
                                                scrollSnapAlign: mobile
                                                  ? "center"
                                                  : "",
                                                scrollSnapStop: mobile
                                                  ? "always"
                                                  : "",
                                              }}
                                              onClick={(e) =>
                                                handleSetSelectedProductID(
                                                  product.product_id,
                                                  product.product_name,
                                                  product.product_desc,
                                                  product.product_color,
                                                  product.product_material,
                                                  product.product_price,
                                                  product.product_weight,
                                                  product.product_height,
                                                  product.product_length,
                                                  product.product_width,
                                                  product.product_quantity,
                                                  product.product_category,
                                                  product.images,
                                                  product.product_variants,
                                                )
                                              }
                                            >
                                              <img
                                                src={
                                                  product.images[0]
                                                    ?.thumbnail_url
                                                }
                                                alt={product.product_name}
                                                style={{
                                                  height: "auto",
                                                  maxHeight: "20vh",
                                                  width: "auto",
                                                  maxWidth: "90%",
                                                  objectFit: "cover",
                                                  borderRadius: "8px",
                                                }}
                                              />
                                              <h2
                                                style={{
                                                  margin: "10px 0",
                                                  fontSize: mobile
                                                    ? "1rem"
                                                    : "1.2rem",
                                                }}
                                              >
                                                {product.product_name}
                                              </h2>
                                              <p
                                                style={{
                                                  margin: "5px 0",
                                                  fontSize: mobile
                                                    ? ".8rem"
                                                    : "1rem",
                                                }}
                                              >
                                                $
                                                {product.product_price.toFixed(
                                                  2,
                                                )}
                                              </p>
                                            </div>
                                          ))}
                                        </div>
                                      ))}
                                  </div>
                                ),
                              )
                            : null
                        }                       
                        
                        
                        </>
                      )}

                     
                    </div>
                  );
                }
                return null; // Skip rendering for other categories.filter((category) => category.public)
              })
          //SHOP ALL RENDER
            : currentCategories?.filter((category) => category.public).map((category, index) => {
                const categoryProducts = currentProducts?.filter(
                  (product) =>
                    product.product_category === category.category_id,
                );

                return (
                  <div key={index} style={{ marginBottom: "30px" }}>
                    {mobile ? (
                      <>
                        <h2 style={{ textAlign: "center" }}>
                          {category.category_name}
                        </h2>
                        {categoryProducts?.length > 0 ? (
                          Object.entries(
                            categoryProducts.reduce((acc, product) => {
                              const subcategoryName = getSubcategoryName(
                                product.product_category,
                                product.product_subcategory,
                              );
                              if (!acc[subcategoryName]) {
                                acc[subcategoryName] = [];
                              }
                              acc[subcategoryName].push(product);
                              return acc;
                            }, {}),
                          )
                          .sort(([a], [b]) => a.localeCompare(b)) 
                          .map(
                            (
                              [subcategoryName, subcategoryProducts],
                              subIndex,
                            ) => (
                              <>
                                <MobileSubcategoryRow
                                  subcategoryName={subcategoryName}
                                  subcategoryProducts={subcategoryProducts}
                                  handleSetSelectedProductID={handleSetSelectedProductID}
                                />
                              </>
                            ),
                          )
                        ) : (
                          <>

                          
                          </>
                        )}
                      </>

                    ) : (
                      <>
                                              <h2 style={{ textAlign: "center" }}>
                          {category.category_name}
                        </h2>
{
  
                          categoryProducts?.length > 0
                            ? Object.entries(
                                categoryProducts.reduce((acc, product) => {
                                  const subcategoryName = getSubcategoryName(
                                    product.product_category,
                                    product.product_subcategory,
                                  );
                                  if (!acc[subcategoryName]) {
                                    acc[subcategoryName] = [];
                                  }
                                  acc[subcategoryName].push(product);
                                  return acc;
                                }, {}),
                              )
                              .sort(([a], [b]) => a.localeCompare(b)) 
                              .map(
                                (
                                  [subcategoryName, subcategoryProducts],
                                  subIndex,
                                ) => (
                                  <div
                                    key={subIndex}
                                    style={{
                                      marginBottom: "20px",
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >

                                    <h3
                                      style={{
                                        textAlign: "center",
                                        fontSize: "1.2rem",
                                        fontWeight: 600,
                                      }}
                                    >
                                      <strong>{subcategoryName}</strong>
                                    </h3>

                                    {subcategoryProducts
                                      .reduce((rows, product, index) => {
                                        if (index % 4 === 0) rows.push([]); // Start a new row
                                        rows[rows.length - 1].push(product); // Add product to the last row
                                        return rows;
                                      }, [])
                                      .map((rowProducts, rowIndex) => (
                                        <div
                                          key={rowIndex}
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: mobile ? "" : "5vw",
                                            alignItems: "center",
                                            justifyContent: mobile
                                              ? ""
                                              : "center",
                                            width: mobile ? "58vw" : "100%",
                                            height: "38vh",
                                            overflowX: mobile ? "auto" : "auto",
                                            paddingTop: "1vh",
                                            paddingBottom: "1vh",
                                            marginBottom: "5vh",
                                            flex: "0 0 auto",
                                            paddingRight: "1vw",
                                            // backgroundColor:'red',
                                            scrollSnapType: mobile
                                              ? "x mandatory"
                                              : "",
                                          }}
                                        >
                                          {rowProducts.map((product) => (
                                            <div
                                              key={product.product_id}
                                              style={{
                                                backgroundColor: "white",
                                                boxShadow:
                                                  "1px 1px 6px 2px black",
                                                padding: "10px",
                                                borderRadius: "8px",
                                                textAlign: "center",
                                                flex: "0 0 auto",
                                                width: mobile ? "56vw" : "15vw",
                                                height: mobile
                                                  ? "38vh"
                                                  : "35vh",
                                                margin: mobile ? "0 20vw" : "",
                                                marginnRight: "20vw",
                                                boxSizing: "border-box",
                                                scrollSnapAlign: mobile
                                                  ? "center"
                                                  : "",
                                                scrollSnapStop: mobile
                                                  ? "always"
                                                  : "",
                                              }}
                                              onClick={(e) =>
                                                handleSetSelectedProductID(
                                                  product.product_id,
                                                  product.product_name,
                                                  product.product_desc,
                                                  product.product_color,
                                                  product.product_material,
                                                  product.product_price,
                                                  product.product_weight,
                                                  product.product_height,
                                                  product.product_length,
                                                  product.product_width,
                                                  product.product_quantity,
                                                  product.product_category,
                                                  product.images,
                                                  product.product_variants,
                                                )
                                              }
                                            >
                                              <img
                                                src={
                                                  product.images[0]
                                                    ?.thumbnail_url
                                                }
                                                alt={product.product_name}
                                                style={{
                                                  height: "auto",
                                                  maxHeight: "20vh",
                                                  width: "auto",
                                                  maxWidth: "90%",
                                                  objectFit: "cover",
                                                  borderRadius: "8px",
                                                }}
                                              />
                                              <h2
                                                style={{
                                                  margin: "10px 0",
                                                  fontSize: mobile
                                                    ? "1rem"
                                                    : "1.2rem",
                                                }}
                                              >
                                                {product.product_name}
                                              </h2>
                                              <p
                                                style={{
                                                  margin: "5px 0",
                                                  fontSize: mobile
                                                    ? ".8rem"
                                                    : "1rem",
                                                }}
                                              >
                                                $
                                                {product.product_price.toFixed(
                                                  2,
                                                )}
                                              </p>
                                            </div>
                                          ))}
                                        </div>
                                      ))}
                                  </div>
                                ),
                              )
                            : null
                        }                       
                      </>
                    )}
                  </div>
                );
              })}
        </div>
        <Footer />
      </div>
    </>
  );
};
