import React, { useContext } from "react";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { AboutUsText } from "./aboutUsText";
import { DataContext } from "../../common/DataContext";
import { ShoppingCartModal } from "../../components/common";

export const About = () => {
  const { mobile } = useContext(DataContext);

  return (
    <>
      <div
        style={{
          backgroundColor: "rgb(78, 171, 218)",
          height: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          position: "relative", // Ensure fixed elements work with this container
        }}
      >
        {/* Fixed Header */}
        <div
          style={{
            position: mobile ? "fixed" : "static",
            top: 0,
            width: "100%",
            zIndex: 1000, // Ensure header stays on top
          }}
        >
          <Header />
        </div>

        <ShoppingCartModal />

        {/* Scrollable Content */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flexGrow: 1, // Allow this section to take available space
            marginTop: mobile ? "10vh" : "0", // Leave space for fixed header
            marginBottom: mobile ? "10vh" : "0", // Leave space for fixed footer
            overflowY: mobile ? "scroll" : "auto", // Only scroll on mobile
          }}
        >
          <AboutUsText />
        </div>

        {/* Fixed Footer */}
        <div
          style={{
            position: mobile ? "fixed" : "static",
            bottom: 0,
            width: "100%",
            zIndex: 1000, // Ensure footer stays on top
          }}
        >
          <Footer />
        </div>
      </div>
    </>
  );
};
