import React, { useState, useContext, useEffect } from "react";
import "./styles.css";
import { AuthContext } from "../../common/AuthContext";
import { Sidebar } from "../../components/common";
import { DataContext } from "../../common/DataContext";
import { StyledButton } from "../../components/common";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import useToggle from "../../hooks/useToggle";
import closeIcon from "../../static/images/close_icon.png";
export const AdminOrdersPage = () => {
  const {
    handleSetSelectedRow,
    orders,
    setOrders,
    fetchOrders,
    updateOrder,
    handleNavigate,
  } = useContext(DataContext);
  const { user } = useContext(AuthContext);
  const [selectedTab, setSelectedTab] = useState(0);

  const [processingOrders, setProcessingOrders] = useState(null);
  const [completedOrders, setCompletedOrders] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [editModalOpen, toggleEditModalOpen] = useToggle(null);

  useEffect(() => {
    handleSetSelectedRow("Orders");
  }, []);

  useEffect(() => {
    if (user === null) {
      handleNavigate("/login");
    }
    if (user && user.role != "Admin") {
      handleNavigate("/login");
    } else if (user && user.role == "Admin") {
      fetchOrders();
    }
  }, [user]);

  useEffect(() => {
    if (orders && orders.orders.length > 0) {
      setProcessingOrders(
        orders.orders.filter((order) => order.shipping_status === "Processing"),
      );
      setCompletedOrders(
        orders.orders.filter((order) => order.shipping_status === "Completed"),
      );
    }
  }, [orders]);

  const EditOrderModal = (props) => {
    const [trackingNumber, setTrackingNumber] = useState(null);
    const [status, setStatus] = useState(null);

    useEffect(() => {
      if (editModalOpen && editModalOpen == true) {
        setStatus(props.order.shipping_status);
        setTrackingNumber(props.order.tracking_number);
      }
    }, [editModalOpen]);

    const handleUpdateOrder = () => {
      updateOrder(props.order.order_id, trackingNumber, status);
      handleToggleEditOrderModal(false);
    };

    const handleSetStatus = (e) => {
      setStatus(e.target.value);
    };

    const handleSetTracking = (e) => {
      setTrackingNumber(e.target.value);
    };
    return (
      <>
        {editModalOpen && (
          <>
            <div
              style={{
                position: "fixed",
                left: "40vw",
                top: "12vh",
                width: "40vw",
                height: "auto",
                backgroundColor: "ghostwhite",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "top",
                boxShadow: "1px 1px 6px 2px black",
                zIndex: "999",
                padding: "20px",
                overflowY: "auto",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  right: "2%",
                  top: "2%",
                  width: "2vh",
                  height: "2vh",
                  // backgroundColor: "black",
                }}
                onClick={(e) => handleToggleEditOrderModal(false)}
              >
                <img
                  style={{
                    width: "auto",
                    height: "2vh",
                    margin: "auto",
                  }}
                  src={closeIcon}
                />
              </div>
              <h2>Order Details</h2>
              <div style={{ marginBottom: "10px" }}>
                <strong>Order ID:</strong> {props.order.order_id}
              </div>
              <div style={{ marginBottom: "10px" }}>
                <strong>Customer Name:</strong> {props.order.customer_name}
              </div>
              <div style={{ marginBottom: "10px" }}>
                <strong>Email:</strong> {props.order.email}
              </div>
              <div style={{ marginBottom: "10px" }}>
                <strong>Shipping Address:</strong>{" "}
                {props.order.shipping_address}, {props.order.city},{" "}
                {props.order.state}, {props.order.postal_code},{" "}
                {props.order.country}
              </div>
              <div style={{ marginBottom: "10px" }}>
                <strong>Shipping Method:</strong> {props.order.shipping_method}
              </div>
              <div style={{ marginBottom: "10px" }}>
                <strong>Shipping Price:</strong> ${props.order.shipping_price}
              </div>
              <div style={{ marginBottom: "10px" }}>
                <strong>Subtotal:</strong> ${props.order.subtotal}
              </div>
              <div style={{ marginBottom: "10px" }}>
                <strong>Total:</strong> ${props.order.total}
              </div>
              <div style={{ marginBottom: "10px" }}>
                <strong>Tracking Number:</strong>
                <input
                  type="text"
                  value={trackingNumber}
                  onChange={(e) => handleSetTracking(e)}
                  style={{ marginLeft: "10px", padding: "5px" }}
                />
              </div>
              <div style={{ marginBottom: "10px" }}>
                <strong>Shipping Status:</strong>
                <select
                  value={status}
                  onChange={(e) => handleSetStatus(e)}
                  style={{ marginLeft: "10px", padding: "5px" }}
                >
                  <option value="Processing">Processing</option>
                  <option value="Completed">Completed</option>
                  <option value="Refunded">Refunded</option>
                  <option value="Archived">Archived</option>
                </select>
              </div>
              <div>
                <button
                  onClick={() => handleToggleEditOrderModal(false)}
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </button>
                <button onClick={(e) => handleUpdateOrder(e)}>Update</button>
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  const handleSetSelectedOrder = (order) => {
    setSelectedOrder(order);
  };

  const handleToggleEditOrderModal = (e) => {
    if (e) {
      toggleEditModalOpen()(e);
    } else {
      toggleEditModalOpen();
    }
  };

  const handlePrintOrder = (order) => {
    const orderText = `
Order ID: ${order.order_id}
Customer Name: ${order.customer_name}
Email: ${order.email}
Shipping Address: ${order.shipping_address}, ${order.city}, ${order.state}, ${
      order.postal_code
    }, ${order.country}
Shipping Method: ${order.shipping_method}
Shipping Price: $${order.shipping_price}
Subtotal: $${order.subtotal}
Total: $${order.total}
Tracking Number: ${order.tracking_number || "N/A"}
Shipping Status: ${order.shipping_status}
    `;

    const blob = new Blob([orderText], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Order_${order.order_id}.txt`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      <EditOrderModal order={selectedOrder} />
      <div
        className="adminOrders"
        // onClick={(e)=>handleSetSelectedOrder(null)}
        style={{
          display: "flex",
          width: "100%",
          height: "auto",
          flexDirection: "row",
        }}
      >
        <Sidebar />
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              height: "10%",
              backgroundColor: "white",
              boxShadow: "1px 1px 6px 2px black",
              alignItems: "center",
              justifyContent: "right",
            }}
          >
            <StyledButton
              buttonText={"Edit"}
              buttonAction={handleToggleEditOrderModal}
            />
          </div>
          <Tabs
            selectedIndex={selectedTab}
            onSelect={(index) => setSelectedTab(index)}
          >
            <TabList
              style={{
                display: "flex",
                flexDirection: "row",
                listStyleType: "none",
                padding: 0,
                margin: 0,
              }}
            >
              <Tab
                style={{
                  flex: 1,
                  padding: "10px",
                  backgroundColor:
                    selectedTab === 0 ? "lightgrey" : "ghostwhite",
                  cursor: "pointer",
                  border: "1px solid #ccc",
                  textAlign: "center",
                }}
              >
                Processing
              </Tab>
              <Tab
                style={{
                  flex: 1,
                  padding: "10px",
                  backgroundColor:
                    selectedTab === 1 ? "lightgrey" : "ghostwhite",
                  cursor: "pointer",
                  border: "1px solid #ccc",
                  textAlign: "center",
                }}
              >
                Completed
              </Tab>
              <Tab
                style={{
                  flex: 1,
                  padding: "10px",
                  backgroundColor:
                    selectedTab === 2 ? "lightgrey" : "ghostwhite",
                  border: "1px solid #ccc",
                  cursor: "pointer",
                  textAlign: "center",
                }}
              >
                Refunded
              </Tab>
              <Tab
                style={{
                  flex: 1,
                  padding: "10px",
                  border: "1px solid #ccc",
                  backgroundColor:
                    selectedTab === 3 ? "lightgrey" : "ghostwhite",
                  cursor: "pointer",
                  textAlign: "center",
                }}
              >
                Archived
              </Tab>
            </TabList>

            <TabPanel>
              <div
                style={{
                  overflowY: "scroll",
                  height: "92vh",
                }}
              >
                {processingOrders &&
                processingOrders !== null &&
                processingOrders.length > 0
                  ? processingOrders.map((order) => (
                      <div
                        key={order.order_id}
                        onClick={(e) => handleSetSelectedOrder(order)}
                        style={{
                          backgroundColor:
                            selectedOrder === order ? "#87CEFA" : "ghostwhite",
                          margin: "10px",
                          padding: "10px",
                          border: "1px solid #ccc",
                        }}
                      >
                        <p>Order ID: {order.order_id}</p>
                        <p>Customer Name: {order.customer_name}</p>
                        <p>Email: {order.email}</p>
                        <p>
                          Shipping Address: {order.shipping_address},{" "}
                          {order.city}, {order.country}
                        </p>
                        <p>Subtotal: ${order.subtotal}</p>
                        <p>Shipping Price: ${order.shipping_price}</p>
                        <p>Total: ${order.total}</p>
                        <p>Shipping Method: {order.shipping_method}</p>
                        <p>Shipping Status: {order.shipping_status}</p>
                      </div>
                    ))
                  : null}
              </div>
            </TabPanel>

            <TabPanel>
              <div
                style={{
                  overflowY: "scroll",
                  height: "92vh",
                }}
              >
                {completedOrders &&
                processingOrders !== null &&
                completedOrders.length > 0
                  ? completedOrders.map((order) => (
                      <div
                        key={order.order_id}
                        onClick={(e) => handleSetSelectedOrder(order)}
                        style={{
                          backgroundColor:
                            selectedOrder === order ? "#87CEFA" : "ghostwhite",

                          margin: "10px",
                          padding: "10px",
                          border: "1px solid #ccc",
                        }}
                      >
                        <p>Order ID: {order.order_id}</p>
                        <p>Customer Name: {order.customer_name}</p>
                        <p>Email: {order.email}</p>
                        <p>
                          Shipping Address: {order.shipping_address},{" "}
                          {order.city}, {order.country}
                        </p>
                        <p>Subtotal: ${order.subtotal}</p>
                        <p>Shipping Price: ${order.shipping_price}</p>
                        <p>Total: ${order.total}</p>
                        <p>Shipping Method: {order.shipping_method}</p>
                        <p>Shipping Status: {order.shipping_status}</p>
                        <p>Tracking Number: {order.tracking_number}</p>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePrintOrder(order);
                          }}
                        >
                          Print
                        </button>
                      </div>
                    ))
                  : null}
              </div>
            </TabPanel>

            <TabPanel>
              <div
                style={{
                  overflowY: "scroll",
                  height: "92vh",
                }}
              >
                {orders &&
                orders.orders.filter(
                  (order) => order.shipping_status === "Refunded",
                ).length > 0
                  ? orders.orders
                      .filter((order) => order.shipping_status === "Refunded")
                      .map((order) => (
                        <div
                          key={order.order_id}
                          onClick={(e) => handleSetSelectedOrder(order)}
                          style={{
                            backgroundColor:
                              selectedOrder === order
                                ? "#87CEFA"
                                : "ghostwhite",

                            margin: "10px",
                            padding: "10px",
                            border: "1px solid #ccc",
                          }}
                        >
                          <p>Order ID: {order.order_id}</p>
                          <p>Customer Name: {order.customer_name}</p>
                          <p>Email: {order.email}</p>
                          <p>
                            Shipping Address: {order.shipping_address},{" "}
                            {order.city}, {order.country}
                          </p>
                          <p>Subtotal: ${order.subtotal}</p>
                          <p>Shipping Price: ${order.shipping_price}</p>
                          <p>Total: ${order.total}</p>
                          <p>Shipping Method: {order.shipping_method}</p>
                          <p>Shipping Status: {order.shipping_status}</p>
                          <p>Tracking Number: {order.tracking_number}</p>
                        </div>
                      ))
                  : null}
              </div>
            </TabPanel>

            <TabPanel>
              <div
                style={{
                  overflowY: "scroll",
                  height: "92vh",
                }}
              >
                {orders &&
                orders.orders.filter(
                  (order) => order.shipping_status === "Archived",
                ).length > 0
                  ? orders.orders
                      .filter((order) => order.shipping_status === "Archived")
                      .map((order) => (
                        <div
                          key={order.order_id}
                          onClick={(e) => handleSetSelectedOrder(order)}
                          style={{
                            backgroundColor:
                              selectedOrder === order
                                ? "#87CEFA"
                                : "ghostwhite",

                            margin: "10px",
                            padding: "10px",
                            border: "1px solid #ccc",
                          }}
                        >
                          <p>Order ID: {order.order_id}</p>
                          <p>Customer Name: {order.customer_name}</p>
                          <p>Email: {order.email}</p>
                          <p>
                            Shipping Address: {order.shipping_address},{" "}
                            {order.city}, {order.country}
                          </p>
                          <p>Subtotal: ${order.subtotal}</p>
                          <p>Shipping Price: ${order.shipping_price}</p>
                          <p>Total: ${order.total}</p>
                          <p>Shipping Method: {order.shipping_method}</p>
                          <p>Shipping Status: {order.shipping_status}</p>
                          <p>Tracking Number: {order.tracking_number}</p>
                        </div>
                      ))
                  : null}
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </>
  );
};
