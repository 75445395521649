import React, { useState, useContext, useEffect } from "react";
import logo from "../../static/images/cart.png";
import { NavLink, useNavigate } from "react-router-dom"; // Use useNavigate to handle navigation
import styled from "styled-components";
import { DataContext } from "../../common/DataContext";
import "./styles.css";

const AppBar = styled.header`
  font-family: "Hind Guntur", sans-serif;
  font-size: 14px;
  line-height: 1.4285em;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  width: 100%;
  display: flex;
  z-index: 1100;
  box-sizing: border-box;
  flex-direction: row;
  position: static;
  color: rgba(0, 0, 0, 0.87);
  background-color: #050505;
  margin-bottom: 0;
  align-items: center;
  gap: 4vw;
  padding-left: 2vw;
`;

const DropdownMenu = styled.div`
  position: absolute;
  background-color: white;
  top: 100%;
  left: 0;
  width: 200px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 100;
  display: ${(props) => (props.isVisible ? "block" : "none")};
`;

const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: lightgray;
  }
`;

export const Header = () => {
  const { cartOpen, toggleCartOpen, mobile, handleToggleCartOpen, fetchCategories, currentCategories } = useContext(DataContext);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
  }, []);

  const NavImg = () => {
    const { handleToggleCartOpen } = useContext(DataContext);
    return (
      <img
        style={{
          height: mobile ? "5vh" : "5vh",
          width: "auto",
          marginRight: "2vw",
        }}
        className="navImg"
        src={logo}
        onClick={() => handleToggleCartOpen()}
        alt="Cart"
      />
    );
  };

  const Nav_Link = (props) => {
    return (
      <h6
        className="headerlink"
        style={{
          width: mobile ? "auto" : "5vw",
          margin: "0vw",
        }}
      >
        {props.text}
      </h6>
    );
  };

  const handleCategoryClick = (category) => {
    // Format the category name to replace spaces with hyphens
    const formattedCategoryName = category.category_name.replace(/\s+/g, "-").toLowerCase();
    navigate(`/shop/${formattedCategoryName}`); // Navigate to the category-specific shop page
  };

  return (
    <AppBar>



      <div
        style={{ position: "relative" }}
        onMouseEnter={() => setDropdownVisible(false)}
      >

        <NavLink
        to="/"
        style={{ color: "whitesmoke", textDecoration: "none" }}
      >
        <Nav_Link text={"HOME"} />
      </NavLink>
      </div>
      <div
        style={{ position: "relative" }}
        onMouseEnter={() => setDropdownVisible(false)}
      >

        <NavLink
        to="/about"
        style={{ color: "whitesmoke", textDecoration: "none" }}
      >
        <Nav_Link text={"ABOUT"} />
      </NavLink>
      </div>


      <div
        style={{ position: "relative" }}
        onMouseEnter={() => setDropdownVisible(true)}
        onMouseLeave={() => setDropdownVisible(false)}
      >
        <NavLink
          to="/shop"
          style={{ color: "whitesmoke", textDecoration: "none" }}
        >
          <Nav_Link text={"SHOP"} />
        </NavLink>

        <DropdownMenu isVisible={isDropdownVisible}>
        <DropdownItem
              key={'all'}
              onClick={() =>  navigate('/shop')} // Handle category click
            >
              {"Shop All"}
            </DropdownItem>
          {currentCategories && currentCategories.map((category) => (
            <DropdownItem
              key={category.category_id}
              onClick={() => handleCategoryClick(category)} // Handle category click
            >
              {category.category_name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </div>

      <div
        style={{ position: "relative" }}
        onMouseEnter={() => setDropdownVisible(false)}
      >

        <NavLink
        to="/FAQ"
        style={{ color: "whitesmoke", textDecoration: "none" }}
      >
        <Nav_Link text={"FAQ"} />
      </NavLink>
      </div>
      <div
        style={{ position: "relative" }}
        onMouseEnter={() => setDropdownVisible(false)}
      >

        <NavLink
        to="/contact"
        style={{ color: "whitesmoke", textDecoration: "none" }}
      >
        <Nav_Link text={"CONTACT"} />
      </NavLink>
      </div>

      <span style={{ width: "90%" }} />

      <NavImg />
    </AppBar>
  );
};
