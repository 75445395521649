// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adminDash {
  position: fixed;
  width: 100%;
  height: 100%;
  margin-top: 0vh;
  margin: 0%;
  padding: 0px;
  overflow: auto;
  background-color: rgb(78, 171, 218);
  /* background-color: rgba(233, 239, 243, 0.29); */
  background-size: cover;
}
/* .textDiv{
  margin-left:20%;
  margin-top:1%;
  
  margin-bottom:0vh;
  padding-bottom:1vh;
  background-color: rgba(255, 13, 13, 0.65);
  width:90% ;
  height:100%;
} */

.arrow {
  width: 1.5rem;

  margin-left: 1vw;
  margin-right: 0vw;
  margin-top: 0.25vw;
}
@media only screen and (max-width: 384px) {
  .textDiv {
    margin: auto;
    width: 80%;
    background-color: rgba(0, 0, 0, 0.65);
    margin-bottom: 2vh;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/AdminCategories/styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,eAAe;EACf,UAAU;EACV,YAAY;EACZ,cAAc;EACd,mCAAmC;EACnC,iDAAiD;EAIjD,sBAAsB;AACxB;AACA;;;;;;;;;GASG;;AAEH;EACE,aAAa;;EAEb,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE;IACE,YAAY;IACZ,UAAU;IACV,qCAAqC;IACrC,kBAAkB;EACpB;AACF","sourcesContent":[".adminDash {\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  margin-top: 0vh;\n  margin: 0%;\n  padding: 0px;\n  overflow: auto;\n  background-color: rgb(78, 171, 218);\n  /* background-color: rgba(233, 239, 243, 0.29); */\n  -webkit-background-size: cover;\n  -moz-background-size: cover;\n  -o-background-size: cover;\n  background-size: cover;\n}\n/* .textDiv{\n  margin-left:20%;\n  margin-top:1%;\n  \n  margin-bottom:0vh;\n  padding-bottom:1vh;\n  background-color: rgba(255, 13, 13, 0.65);\n  width:90% ;\n  height:100%;\n} */\n\n.arrow {\n  width: 1.5rem;\n\n  margin-left: 1vw;\n  margin-right: 0vw;\n  margin-top: 0.25vw;\n}\n@media only screen and (max-width: 384px) {\n  .textDiv {\n    margin: auto;\n    width: 80%;\n    background-color: rgba(0, 0, 0, 0.65);\n    margin-bottom: 2vh;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
