import React, { createContext, useState, useEffect } from "react";
import { useLocalStorageState } from "../useLocalStorage";
import { poster, fetcher } from "../../calls/calls";
import { API_URL } from "../constants";
import useToggle from "../../hooks/useToggle";

export const AuthContext = createContext({});
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorageState("stickers.user", null);
  const [loginRedirect, setLoginRedirect] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");

  const logInUser = async () => {
    let url = API_URL + "/login";
    let outpack = {
      email: email,
      password: password,
    };
    poster(outpack, url).then((response) => {
      if (response.status === 200) {
        setUser(response.user_info);
        return true;
      } else {
        alert(response.message);
        return false;
      }
    });
  };

  const logout = () => {
    setUser(null);

    setEmail(null);
    setPassword(null);
    let url = API_URL + "/logout";
    fetcher(url).then((response) => {});
  };

  const value = {
    logout,
    user,
    setUser,
    logInUser,
    loginRedirect,
    setLoginRedirect,
    email,
    setEmail,
    password,
    setPassword,
    code,
    setCode,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
