import React, { useEffect, useContext, useState } from "react";
import useToggle from "../../hooks/useToggle";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { DataContext } from "../../common/DataContext";
import { useNavigate, useParams } from "react-router-dom";
import { ShoppingCartModal } from "../../components/common";
import leftIcon from "../../static/images/leftIcon.png";
import rightIcon from "../../static/images/rightIcon.png";
import closeIcon from "../../static/images/close_icon.png";
import { ImagePreviewModal } from "../AdminProducts/adminProductComponents";
export const Product = () => {
  const { 
    mobile, 
    cartOpen, 
    fetchTargetProduct, 
    targetProduct,
    handleAddItemToCart,
  
  } = useContext(DataContext);

  const [previewImages, setPreviewImages] = useState(null);
  const [previewModalOpen, togglePreviewModalOpen] = useToggle(false);

  const [imageIndex, setImageIndex] = useState(0);
  const { categoryName } = useParams(); // Capture the category name from the URL
  const [addQuantity, setAddQuantity] = useState(0);
  const [variantName, setvariantName] = useState(null);
  const [variantQuantity, setvariantQuantity] = useState(null);
  const [variantPrice, setvariantPrice] = useState(null);
  const [variantLength, setvariantLength] = useState(null);
  const [variantWidth, setvariantWidth] = useState(null);
  const [variantHeight, setvariantHeight] = useState(null);
  const [variantMaterial, setVariantMaterial] = useState(null);
  const [variantWeight, setvariantWeight] = useState(null);
  // Fetch all products on initial load
  useEffect(() => {
    if (targetProduct!==null&&targetProduct.product_variants.length>0){
      setInitialVariant()
    }

  }, [targetProduct]);

  // Match the category from the URL and set the selected category
  useEffect(() => {
    // Get the current URL
    let currentUrl = window.location.href;
    
    // Remove any query parameters by splitting at "?" and taking the first segment
    let cleanUrl = currentUrl.split("?")[0];
    
    // Extract the last segment of the URL path
    let lastSegment = cleanUrl.split("/").pop();
    
    // Format the last segment by replacing hyphens with spaces
    let formattedSegment = lastSegment.replace(/(?<! )-(?! )/g, " ");
    console.log(formattedSegment)
    // Determine if the segment is a number (ID) or a text (slug)
    if (!isNaN(lastSegment)) {
      // console.log("Product ID:", parseInt(lastSegment, 10));
      fetchTargetProduct(formattedSegment, null);
    } else {
      fetchTargetProduct(null, formattedSegment);
    }
  }, []);
  



  const handleAddItem = (
    e,
    product_id,
    product_name,
    images,
    product_price,
    addQuantity,
    length,
    width,
    height,
    weight
  ) => {

    handleAddItemToCart(
      e,
      product_id,
      product_name,
      images,
      variantPrice&&variantPrice!==null?variantPrice:product_price,
      addQuantity,
      variantLength&&variantLength!==null?variantLength:length,
      variantWidth&&variantWidth!==null?variantWidth:width,
      variantHeight&&variantHeight!==null?variantHeight:height,
      variantWeight&&variantWeight!==null?variantWeight:weight,
      variantName&&variantName!==null?variantName:null
    );
    setAddQuantity(0);
  };



  const handleTogglePreviewModalOpen = (e) => {
    e.stopPropagation();

    togglePreviewModalOpen();

  };


  const setInitialVariant=(e)=>{
    let index= 0
    let selectedVariant=targetProduct.product_variants[index]

    setvariantName(selectedVariant.name)
    setvariantQuantity(selectedVariant.quantity)
    setvariantPrice(selectedVariant.price)
    setvariantLength(selectedVariant.length)
    setvariantWidth(selectedVariant.width)
    setvariantHeight(selectedVariant.height)
    setvariantWeight(selectedVariant.weight)
    setVariantMaterial(selectedVariant.material)
    setAddQuantity(0)
  }


  const handleSelectedvariant=(e)=>{
    let index= parseInt(e.target.value)
    let selectedVariant=targetProduct.product_variants[index]
    setvariantName(selectedVariant.name)
    setvariantQuantity(selectedVariant.quantity)
    setvariantPrice(selectedVariant.price)
    setvariantLength(selectedVariant.length)
    setvariantWidth(selectedVariant.width)
    setvariantHeight(selectedVariant.height)
    setvariantWeight(selectedVariant.weight)
    setVariantMaterial(selectedVariant.material)
    setAddQuantity(0)
  }

  const cycleUpImage = (e) => {
    e.stopPropagation();
    if (imageIndex <targetProduct.images.length - 1) {
      setImageIndex((count) => count + 1);
    } else {
      setImageIndex(0);
    }
  };

  // Function to move an image down in the order
  const cycleDownImage = (e) => {
    e.stopPropagation();
    if (imageIndex - 1 >= 0) {
      setImageIndex((count) => count - 1);
    } 
  };

  const handleSetAddQuantity = (e, quantity, op = null) => {
    if (!op) {
      setAddQuantity(e.target.value);
    } else {
      let tempQuant = addQuantity;
      let tempCount=tempQuant+addQuantity
      if(tempCount <= quantity){
        if (op === "+") {
          tempQuant += 1;
        } else if (op === "-") {
          tempQuant -= 1;
        }
        setAddQuantity(tempQuant);
      }

    }
  };


return (
  <>
    <div
      style={{
        height: "100vh",
        width:'100vw',
        display: "flex",
        flexDirection: "column",
        backgroundColor: "rgb(78, 171, 218)",
      }}
    >
      {/* Fixed Header */}
      <div
        style={{
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 1000, // Ensure header stays on top
        }}
      >
        <Header />
      </div>
      <ShoppingCartModal />

      <div
        style={{
          flex: 1,
          overflowY: "auto",
          width:'100vw',
          overflowX: "hidden", // Prevent horizontal scrolling
          marginTop: mobile ? "30px" : "40px", // Adjust to header height
          marginBottom: "60px", // Adjust to footer height
          display: "flex",
          flexDirection: "column",
          alignItems:'center',
          justifyContent:'center',
          pading:'1vw'
        }}
      >
      <ImagePreviewModal
            modalHandler={handleTogglePreviewModalOpen}
            modalState={previewModalOpen}
            previewImages={targetProduct&&targetProduct.images}
            imageIndex={imageIndex}
            setImageIndex={setImageIndex}
          />
    <div
      style={{


        width: mobile ? "80%" : "90vw",
        // maxWidth: mobile ? "100%" : "25vw",
        
        height: mobile ? "auto" : "80%",
        minHeight:'50vh',
        display: "flex",
        flexDirection: "column",
        // marginBottom: "1vw",
        padding: mobile ? "3vw" : "1.5vw",
        backgroundColor: "white",
        boxShadow: "1px 1px 6px 2px black",
        alignItems: "center",
      }}
      // value={props.product_id}
      // key={props.product_id}
    >
      <div
        style={{
          width: "18vw",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          // backgroundColor:'green'
          marginTop:'1vh',
          marginBottom:'2vh'
        }}
      >
        <img
          src={leftIcon}
          style={{ width: ".70rem", marginRight: ".5rem", marginLeft: ".5rem" }}
          onClick={(e) => cycleDownImage(e)}
        />

        <img
          style={{
            height: mobile ? "6rem" : "15rem",
            width: "auto",

            boxShadow: targetProduct&&targetProduct.images[imageIndex]
              ? "1px 1px 6px 2px grey"
              : null,
          }}
          src={
            targetProduct&&targetProduct.images[imageIndex]
              ? targetProduct.images[imageIndex].thumbnail_url
              : null
          }
          onClick={(e) => handleTogglePreviewModalOpen(e)}
        />

        <img
          src={rightIcon}
          style={{ width: ".70rem", marginLeft: ".5rem", marginRight: ".5rem" }}
          onClick={(e) => cycleUpImage(e)}
        />
      </div>

      <label
          style={{
            fontSize: mobile ? "1rem" : "1.25rem",
            // backgroundColor:'green',
            color: "black",
            fontWeight: mobile ? 500 : 700,
            textAlign: "center",
            marginBottom: "2vh",
          }}
        >
          <strong>{targetProduct&&targetProduct.product_name}</strong>

        </label>

        <label
          style={{
            fontSize: mobile ? "1rem" : "1.25rem",
            // backgroundColor:'green',
            color: "black",
            fontWeight: mobile ? 500 : 700,
            textAlign: "center",
            marginBottom: "2vh",
          }}
        >
        {variantName&&variantName!==null? `\n - ${variantName}`
          :
          null

          }

        </label>



        <label
          style={{
            fontSize: mobile ? ".8rem" : "1.1rem",
            color: "black",
            fontWeight: 500,
            textAlign: "center",
            marginBottom:'1vh'
          }}
        >
          {targetProduct&&targetProduct.product_desc}
        </label>
        <label style={{marginBottom:'1vh'}}><strong>Select Product Variant:</strong></label>
          <select style={{marginBottom:'1vh'}} 
          onChange={(e)=>handleSelectedvariant(e)}
          >
            {targetProduct&&targetProduct.product_variants.length>0?targetProduct.product_variants.map((variant, idx) => (
              <option key={idx} value={idx}>
                {variant.name} - ${variant.price}
              </option>
            )):null
          }
          </select>


          <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "auto",
          width: "100%",
          alignItems: "center",
          justifyContent:'center',
          marginBottom: "1vh",
          gap:'2vw'
        }}
      >
          
          <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
          alignItems: "center",
          marginBottom: "1vh",
        }}
      >
          <label
          style={{
            fontSize: mobile ? ".9rem" : "1.1rem",
            color: "black",
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          <strong>{"Material:"}</strong>
        </label>
        <label
          style={{
            fontSize: mobile ? ".8rem" : "1.1rem",
            color: "black",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {variantMaterial!==null?variantMaterial:targetProduct&&targetProduct.product_material}
        </label>
</div>

<div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
          alignItems: "center",
          marginBottom: "1vh",
        }}
      >
          <label
          style={{
            fontSize: mobile ? ".9rem" : "1.1rem",
            color: "black",
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          <strong>{"Colors:"}</strong>
        </label>
        <label
          style={{
            fontSize: mobile ? ".8rem" : "1.1rem",
            color: "black",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {targetProduct&&targetProduct.product_color}
        </label>
</div>




</div>





<div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "auto",
          width: "100%",
          alignItems: "center",
          justifyContent:'center',
          marginBottom: "1vh",
          gap:'2vw'
        }}
      >
          
          <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
          alignItems: "center",
          marginBottom: "1vh",
        }}
      >
          <label
          style={{
            fontSize: mobile ? ".9rem" : "1.1rem",
            color: "black",
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          <strong>{"Length:"}</strong>
        </label>
        <label
          style={{
            fontSize: mobile ? ".8rem" : "1.1rem",
            color: "black",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {variantLength!==null?variantLength: targetProduct&&targetProduct.product_length}in
        </label>
</div>

<div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
          alignItems: "center",
          marginBottom: "1vh",
        }}
      >
          <label
          style={{
            fontSize: mobile ? ".9rem" : "1.1rem",
            color: "black",
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          <strong>{"Width:"}</strong>
        </label>
        <label
          style={{
            fontSize: mobile ? ".8rem" : "1.1rem",
            color: "black",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {variantWidth!==null?variantWidth:targetProduct&&targetProduct.product_width}in
        </label>
</div>

<div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
          alignItems: "center",
          marginBottom: "1vh",
        }}
      >
          <label
          style={{
            fontSize: mobile ? ".9rem" : "1.1rem",
            color: "black",
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          <strong>{"Height:"}</strong>
        </label>
        <label
          style={{
            fontSize: mobile ? ".8rem" : "1.1rem",
            color: "black",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {targetProduct&&targetProduct.product_height}
        </label>
</div>

<div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
          alignItems: "center",
          marginBottom: "1vh",
        }}
      >
          <label
          style={{
            fontSize: mobile ? ".9rem" : "1.1rem",
            color: "black",
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          <strong>{"Weight:"}</strong>
        </label>
        <label
          style={{
            fontSize: mobile ? ".8rem" : "1.1rem",
            color: "black",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {targetProduct&&targetProduct.product_weight}g
        </label>
</div>

</div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "auto",
          width: "100%",
          alignItems: "center",
          justifyContent:'center',
          
          marginBottom: "1vh",
          gap:'2vw'
        }}
      >
          
          <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
          alignItems: "center",
          marginBottom: "1vh",
        }}
      >
          <label
          style={{
            fontSize: mobile ? ".9rem" : "1.1rem",
            color: "black",
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          <strong>{"Price:"}</strong>
        </label>
        <label
          style={{
            fontSize: mobile ? ".8rem" : "1.1rem",
            color: "black",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          ${targetProduct&&targetProduct.product_price.toPrecision(2)}
        </label>





      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
          alignItems: "center",
          marginBottom: "1vh",
        }}
      >
          <label
          style={{
            fontSize: mobile ? ".9rem" : "1.1rem",
            color: "black",
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          <strong>{"In Stock:"}</strong>
        </label>
        <label
          style={{
            fontSize: mobile ? ".8rem" : "1.1rem",
            color: "black",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {variantQuantity!==null?variantQuantity: targetProduct&&targetProduct.product_quantity}
        </label>





      </div>
      </div>


      <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "auto",
              alignItems: "center",
              justifyContent: "center",
              gap: "2vw",
              // backgroundColor:'grey'
            }}
          >
            {mobile ? (
              <>
                <label
                  style={{
                    // backgroundColor:'red',
                    height: "auto",
                    fontSize: mobile ? "1.3rem" : "1.25rem",
                    textAlign: "center",
                    padding: "0",
                  }}
                >
                  Quantity: 
                </label>
                <button 
                onClick={(e) => handleSetAddQuantity(e,targetProduct.product_quantity, "-")}
                >
                  -
                </button>

                <button 
                onClick={(e) => handleSetAddQuantity(e,targetProduct.product_quantity, "+")}
                >
                  +
                </button>
              </>
            ) : (
              <>
                <input
                  value={addQuantity}
                  onChange={(e) => handleSetAddQuantity(e,  variantQuantity!==null?variantQuantity:targetProduct&&targetProduct.product_quantity)}
                  type="number"
                  min={1}
                  max={variantQuantity!==null?variantQuantity:targetProduct&&targetProduct.product_quantity}
                  style={{
                    width: "8vw",
                    marginRight: "2vw",
                  }}
                ></input>
              </>
            )}

            <button
              onClick={(e) =>
                handleAddItem(
                  e,
                  targetProduct&&targetProduct.product_id,
                  targetProduct&&targetProduct.product_name,
                  targetProduct&&targetProduct.images,
                  variantPrice!==null?variantPrice:targetProduct&&targetProduct.product_price,
                  addQuantity,
                  variantLength!==null?variantLength:targetProduct&&targetProduct.product_length,
                  variantWidth!==null?variantWidth:targetProduct&&targetProduct.product_width,
                  variantHeight!==null?variantHeight:targetProduct&&targetProduct.product_height,
                  variantWeight!==null?variantWeight:targetProduct&&targetProduct.product_weight,
                )
              }
            >
              {"Add to Cart"}
            </button>
          </div>

    </div>





      </div>
      <Footer />
    </div>
  </>
);

  
  
};
