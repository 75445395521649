import React, { useState, useContext, useEffect } from "react";
import products_icon from "../../static/products_icon.png";
import categories_icon from "../../static/categories_icon.png";
import orders_icon from "../../static/orders_icon.png";
import settings_icon from "../../static/settings_icon.png";
import logout_icon from "../../static/logout_icon.png";
import upIcon from "../../static/images/upIcon.png";
import downIcon from "../../static/images/downIcon.png";
import leftIcon from "../../static/images/leftIcon.png";
import rightIcon from "../../static/images/rightIcon.png";
import closeIcon from "../../static/images/close_icon.png";

import { DataContext } from "../../common/DataContext";
import { StyledButton } from "../../components/common";
import useToggle from "../../hooks/useToggle";

export const EditProductModal = (props) => {
 
  const {
    newProductImages,
    setNewProductImages,
    currentImageURLs,
    setCurrentImageURLs,
    handleSetNewProductImages,
    handleAddNewProductImagePreviews,
    selectedProductImages,
    setSelectedProductImages,
  } = useContext(DataContext);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [addVariantOpen,toggleAddVariantOpen] = useToggle(null);
  const [selectedVariantLength,setSelectedVariantLength]=useState(null)
  const [selectedVariantWidth,setSelectedVariantWidth]=useState(null)
  const [selectedVariantHeight,setSelectedVariantHeight]=useState(null)
  const [selectedVariantName,setSelectedVariantName]=useState(null)
  const [selectedVariantColor,setSelectedVariantColor]=useState(null)
  const [selectedVariantMaterial,setSelectedVariantMarterial]=useState(null)
  const [selectedVariantPrice,setSelectedVariantPrice]=useState(null)
  const [selectedVariantWeight,setSelectedVariantWeight]=useState(null)
  const [selectedVariantQuantity,setSelectedVariantQuantity]=useState(null)
  const [selectedVariantIndex,setSelectedVariantIndex]=useState(null)
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    // console.log(props)
  }, [props]);

  const handleSetNewProductLength = (e)=>{
    setSelectedVariantLength(e.target.value)
  } 

  const handleSetNewProductName = (e)=>{
    if (!selectedVariantIndex){
      props.handleSetNewProductName(e)
    }
    else{
      setSelectedVariantName(e.target.value)
    }

  } 

  const handleSetNewProductWidth = (e)=>{
    setSelectedVariantWidth(e.target.value)
  } 

  const handleSetNewProductMaterial = (e)=>{
    setSelectedVariantMarterial(e.target.value)
  } 

  const handleSetNewProductColor = (e)=>{
    setSelectedVariantColor(e.target.value)
  } 
  const handleSetNewProductPrice= (e)=>{
    setSelectedVariantPrice(e.target.value)
  } 
  const handleSetNewProductQuantity= (e)=>{
    setSelectedVariantQuantity(e.target.value)
  } 

  const handleSetNewProductWeight = (e)=>{
    setSelectedVariantWeight(e.target.value)
  } 

  const handleSetNewProductHeight = (e)=>{
    setSelectedVariantHeight(e.target.value)
  } 

  const moveUpImage = (index) => {
    if (index > 0) {
      const updatedPreviews = [...selectedProductImages];
      [updatedPreviews[index - 1], updatedPreviews[index]] = [
        updatedPreviews[index],
        updatedPreviews[index - 1],
      ];
      setSelectedProductImages(updatedPreviews);
    }
  };

  // Function to move an image down in the order
  const moveDownImage = (index) => {
    if (index < selectedProductImages.length - 1) {
      const updatedPreviews = [...selectedProductImages];
      [updatedPreviews[index], updatedPreviews[index + 1]] = [
        updatedPreviews[index + 1],
        updatedPreviews[index],
      ];
      setSelectedProductImages(updatedPreviews);
    }
  };

  const handleSetSelectedCategory = (e) => {
    let categoryId = parseInt(e.target.value);

    setSelectedCategory(categoryId);
    let category =
      props.currentCategories &&
      props.currentCategories.find((cat) => cat.category_id === categoryId);
    if (category) {

      setSubcategories(category.subcategories || []);
    } else {
      setSubcategories([]);
    }
  };

  const handleSetSelectedSubcategory = (e) => {
    let subcategoryId = parseInt(e.target.value);

    setSelectedSubcategory(subcategoryId);
  };



  
  const handleVariantSelected = (e) => {
    let variantName=e.target.value.split(",")[0].toString()
    let variantIndex=parseInt(e.target.value.split(",")[1])
    let selectedVariant = props.variants.find(
      (variant) => variant.name === variantName
    );
    // If the variant is found, update the corresponding states
    if (selectedVariant) {
      setSelectedVariantName(selectedVariant.name);
      setSelectedVariantColor(selectedVariant.color);
      setSelectedVariantMarterial(selectedVariant.material);
      setSelectedVariantPrice(selectedVariant.price);
      setSelectedVariantWeight(selectedVariant.weight);
      setSelectedVariantQuantity(selectedVariant.quantity);
      setSelectedVariantLength(selectedVariant.length)
      setSelectedVariantWidth(selectedVariant.width)
      setSelectedVariantHeight(selectedVariant.height)
      setSelectedVariantIndex(variantIndex)
    }
  };

  const handleEditVariant = ()=>{
    props.handleEditVariant(
      selectedVariantIndex,
      selectedVariantName,
      selectedVariantMaterial,
      selectedVariantLength,
      selectedVariantWidth,
      selectedVariantHeight,
      selectedVariantColor,
      selectedVariantPrice,
      selectedVariantWeight,
      selectedVariantQuantity

    
    )
  }

  return (
    <>
      {props.modal_open && props.modal_open === true ? (
        <>
          <div
            style={{
              position: "fixed",
              left: "20vw",
              top: "12vh",
              width: "60vw",
              height: "85vh",
              backgroundColor: "ghostwhite",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // justifyContent: "top",
              boxShadow: "1px 1px 6px 2px black",
              zIndex: "999",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "10vh",
                // backgroundColor:'red',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "top",

              }}
            >
              <div
                style={{
                  position: "absolute",
                  right: "2%",
                  top: "2%",
                  width: "2vh",
                  height: "2vh",
                  // backgroundColor: "black",
                }}
                onClick={() => props.modal_handler()}
              >
                <img
                  style={{
                    width: "auto",
                    height: "2vh",
                    margin: "auto",
                  }}
                  src={closeIcon}
                />
              </div>

              <h2>{'Edit Product'}</h2>
            </div>

            <div
              style={{
                width: "100%",
                height: "auto",
                backgroundColor:'lightsalmon',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",

          
                overflowX: "scroll",
              }}
            >

<label
                  style={{
                    marginLeft: "1vw",
                    marginRight: "1vw",
                    fontSize:'1.2rem',
                    fontWeight:'600'
                  }}
                >
                  {"Variants:"}
                  
                </label>
                <div
                style={{
                  width: "100%",
                  height: "10vh",
                  backgroundColor:'red',
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  padding:'1vw'
                }}
              >
      <select id="variantSelect"  onChange={(e)=>handleVariantSelected(e)}>
        <option value="" disabled>Select a variant</option>
        {props.variants&&props.variants.map((variant, index) => (
          <option key={index} value={[variant.name,index]}>
            {`${variant.name} - $${variant.price} x ${variant.quantity}`}
          </option>
        ))}
      </select>
      <button
      style={{marginLeft:'2vw'}}
                  onClick={(e)=>handleEditVariant(e)}
                >
                  update variant
                </button>
</div>

              <div
                style={{
                  width: "100%",
                  height: "10vh",
                  backgroundColor:'yellow',
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "left",
                  padding:'1vw'
                }}
              >
                <label
                  style={{
                    marginLeft: "1vw",
                    marginRight: "1vw",
                    fontSize:'1.2rem',
                    fontWeight:'600'
                  }}
                >
                  {"Name:"}
                </label>
                <input
                  type="text"
                  value={selectedVariantName&&selectedVariantName!==null?selectedVariantName:props.newProductName}
                  onChange={(e) => handleSetNewProductName(e)}
                  style={{
                    height: "50%",
                    width: "65%",
                    marginLeft: "2vw",
 
                  }}
                />
              </div>

              <div
                style={{
                  width: "100%",
                  height: "auto",
                  backgroundColor:'orange',
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "left",
                  padding:'1vw'
                }}
              >
                <label
                  style={{
                    marginLeft: "1vw",
                    marginRight: "1vw",
                    fontSize:'1.2rem',
                    fontWeight:'600'
                  }}
                >
                  {"Description:"}
                </label>
                <input
                  type="text"
                  value={props.newProductDesc}
                  onChange={(e) => props.handleSetNewProductDesc(e)}
                  style={{
                    height: "50%",
                    width: "65%",
                    marginLeft: "1vw",

                  }}
                />
              </div>

              <div
                style={{
                  width: "100%",
                  height: "auto",
                  backgroundColor:'blue',
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "left",
                  padding:'1vw'

                }}
              >
                <label
                  style={{
                    marginLeft: "1vw",
                    marginRight: "1vw",
                    fontSize:'1.2rem',
                    fontWeight:'600'
                  }}
                >
                  {"Material:"}
                </label>
                <input
                  type="text"
                  value={selectedVariantMaterial&&selectedVariantMaterial!==null?selectedVariantMaterial:props.newProductMaterial}
                onChange={(e) => handleSetNewProductMaterial(e)}
                  style={{

                  }}
                />

<label
                  style={{
                    marginLeft: "1vw",
                    marginRight: "1vw",
                    fontSize:'1.2rem',
                    fontWeight:'600'
                  }}
                >
                  {"Color:"}
                </label>
                <input
                  type="text"
                  value={selectedVariantColor&&selectedVariantColor!==null?selectedVariantColor:props.newProductColor}
                  onChange={(e) => handleSetNewProductColor(e)}
                  style={{

                    width: "18%",
                    marginLeft: "1vw",
 
                  }}
                />
              </div>



              <div
                style={{
                  width: "100%",
                  height: "10vh",
                  backgroundColor:'green',
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "left",
                  padding:'1vw'
                }}
              >
<label
                  style={{
                    marginLeft: "1vw",
                    marginRight: "1vw",
                    fontSize:'1.2rem',
                    fontWeight:'600'
                  }}
                >
                  {"Length:"}
                </label>
              <input
                type="number"
                step={0.25}
                value={selectedVariantLength&&selectedVariantLength!==null?selectedVariantLength:props.newProductLength}
                onChange={(e) => handleSetNewProductLength(e)}
                style={{
                  height: "50%",
                  width: "5vw",

                }}
              />
<label
                  style={{
                    marginLeft: "1vw",
                    marginRight: "1vw",
                    fontSize:'1.2rem',
                    fontWeight:'600'
                  }}
                >
                  {"Width:"}
                </label>
              <input
                type="number"
                step={0.25}
                value={selectedVariantWidth&&selectedVariantWidth!==null?selectedVariantWidth:props.newProductWidth}
                // placeholder="ex: 2x2x4 in"
                onChange={(e) => handleSetNewProductWidth(e)}
                style={{
                  height: "50%",
                  width: "5vw",
                }}
              />
              
<label
                  style={{
                    marginLeft: "1vw",
                    marginRight: "1vw",
                    fontSize:'1.2rem',
                    fontWeight:'600'
                  }}
                >
                  {"Height:"}
                </label>
              <input
                type="number"
                step={0.25}
                value={selectedVariantHeight&&selectedVariantHeight!==null?selectedVariantHeight:props.newProductHeight}
                // placeholder="ex: 2x2x4 in"
                onChange={(e) =>  handleSetNewProductHeight(e)}
                style={{
                  height: "50%",
                  width: "5vw",
                }}
              />

<label
                  style={{
                    marginLeft: "1vw",
                    marginRight: "1vw",
                    fontSize:'1.2rem',
                    fontWeight:'600'
                  }}
                >
                  {"Weight:"}
                </label>

                <input
                  type="number"
                  value={selectedVariantWeight&&selectedVariantWeight!==null?selectedVariantWeight:props.newProductWeight}
                  onChange={(e) => handleSetNewProductWeight(e)}
                  step={0.01}
                  style={{
                    height: "50%",
                    width: "5vw",
                    marginLeft: "1vw",

                  }}
                />
              </div>

              <div
                style={{
                  width: "100%",
                  height: "10vh",
                  backgroundColor:'purple',
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "left",
                  padding:'1vw'
                }}
              >

<label
                  style={{
                    marginLeft: "1vw",
                    marginRight: "1vw",
                    fontSize:'1.2rem',
                    fontWeight:'600'
                  }}
                >
                  {"Price:"}
                </label>


                <input
                  type="number"
                  step={0.01}
                  value={props.newProductPrice}
                  onChange={(e) => handleSetNewProductPrice(e)}
                  style={{
                    height: "auto",
                    width: "3vw",

                  }}
                />
<label
                  style={{
                    marginLeft: "1vw",
                    marginRight: "1vw",
                    fontSize:'1.2rem',
                    fontWeight:'600'
                  }}
                >
                  {"Quantity:"}
                </label>
                <input
                  type="number"
                  value={props.newProductQuantity}
                  onChange={(e) => handleSetNewProductQuantity(e)}
                  step={1}
                  style={{
                    height: "50%",
                    width: "3vw",
                    marginLeft: "1vw",

                  }}
                />
                                <label
                  style={{
                    marginLeft: "1vw",
                    marginRight: "1vw",
                    fontSize:'1.2rem',
                    fontWeight:'600'
                  }}
                >
                  {"Category:"}
                </label>
              <select
                style={{

                  width: "10vw",
                }}
                onChange={(e) => handleSetSelectedCategory(e)}
                id="categories"
              >

                <option value="">Select a category</option>
                {props.currentCategories&&props.currentCategories.map((category) => (
                  <option
                    key={category.category_id}
                    value={category.category_id}
                  >
                    {category.category_name}
                  </option>
                ))}
              </select>
              <label
                  style={{
                    marginLeft: "1vw",
                    marginRight: "1vw",
                    fontSize:'1.2rem',
                    fontWeight:'600'
                  }}
                >
                  {"SubCategory:"}
                </label>
              <select
                style={{

                  width: "8vw",
                }}
                onChange={(e) => handleSetSelectedSubcategory(e)}
                id="subcategories"
                disabled={!selectedCategory}
              >
                <option value="">Select a subcategory</option>
                {subcategories &&
                  subcategories.map((subcategory) => (
                    <option key={subcategory.id} value={subcategory.id}>
                      {subcategory.name}
                    </option>
                  ))}
              </select>
              </div>

              <div
                style={{
                  height: "48vh",
                  width: "100%",
                  // backgroundColor:'lightpink',
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "top",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "98%",
                    height: "auto",
                    // backgroundColor: "grey",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
 
                    overflowX: "scroll",
                    marginBottom: "1vh",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "4vh",
                      // backgroundColor: "grey",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "left",
                    }}
                  >
                    <h2
                      style={{
                        marginLeft: "1vw",
                        width: "24.5%",
                      }}
                    >
                      {"Images:"}
                    </h2>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      height: "13vh",
                      // backgroundColor: "lightgreen",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "left",
                    }}
                  >
                    {selectedProductImages &&
                      selectedProductImages.slice().map((row, rowindex) => {
                        const { url, thumbnail_url, index } = row;
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginRight: "1vw",
                              width: "5rem",
                              height: "6rem",
                              marginLeft: "1vw",
                            }}
                          >
                            <img
                              style={{
                                width: "5rem",
                                height: "6rem",
                                // margin:'auto'
                              }}
                              src={thumbnail_url}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                height: "3vh",
                                width: "100%",
                                // backgroundColor: "grey",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "1vw",
                                  height: "auto",
                                  // backgroundColor: "lightgrey",

                                  // marginBottom:'1vh'
                                }}
                              >
                                {rowindex}
                              </div>

                              <img
                                src={leftIcon}
                                style={{
                                  width: ".70vw",
                                  marginRight: "1vh",
                                  marginLeft: ".25vw",
                                }}
                                onClick={() => moveUpImage(rowindex)}
                              />
                              <img
                                src={rightIcon}
                                style={{ width: ".70vw", marginRight: ".25vw" }}
                                onClick={() => moveDownImage(rowindex)}
                              />

                              <img
                                style={{
                                  width: "auto",
                                  height: "2vh",
                                  margin: "auto",
                                }}
                                src={closeIcon}
                                onClick={() =>
                                  props.handleDeleteSelectedProductImage(index)
                                }
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div
                  style={{
                    width: "98%",
                    height: "auto",
                    // backgroundColor: "grey",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",

                    overflowX: "scroll",
                    marginBottom: "1vh",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "4vh",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "left",
                    }}
                  >
                    <h2
                      style={{
                        marginLeft: "1vw",
                        width: "30%",
                      }}
                    >
                      {"Add Images:"}
                    </h2>

                    <input
                      type="file"
                      accept=".jpg, .png, .jpeg,"
                      onChange={(e) => handleSetNewProductImages(e)}
                      multiple
                    />
                  </div>

                  <div
                    style={{
                      width: "100%",
                      height: "12vh",
                      // backgroundColor: "lightgrey",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "left",
                    }}
                  >
                    {currentImageURLs &&
                      currentImageURLs.map((row, index) => {

                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginRight: "1vw",
                              width: "5rem",
                              height: "6rem",
                              marginLeft: "1vw",
                              marginTop: "1vh",
                            }}
                          >
                            <img
                              style={{
                                width: "5rem",
                                height: "6rem",
                                // margin:'auto'
                              }}
                              src={row}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                height: "3vh",
                                width: "100%",
                                // backgroundColor: "grey",
                              }}
                            >
                              {/* <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "1vw",
                            height: "auto",
                            backgroundColor: "lightgrey",
                            marginLeft: ".5vw",
                            marginRight: ".5vw",
                            // marginBottom:'1vh'
                          }}
                        >
                          {index}
                        </div> */}
                              {/* 
                        <img
                          src={leftIcon}
                          style={{ width: ".70vw", marginRight: ".5vw" }}
                          onClick={() => moveUpImage(index)}
                        />
                        <img
                          src={rightIcon}
                          style={{ width: ".70vw" }}
                          onClick={() => moveDownImage(index)}
                        /> */}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                height: "10vh",
                // backgroundColor:'grey',
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "2vh",
                marginTop: "2vh",
              }}
            >
              <StyledButton
                buttonText={"Cancel"}
                buttonAction={() => props.modal_handler()}
              />
              <StyledButton
                buttonText={"Edit"}
                buttonAction={() => props.handleEditProduct(selectedCategory,selectedSubcategory)}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export const AddProductModal = (props) => {
  const {
    newProductImages,
    setNewProductImages,
    currentImageURLs,
    setCurrentImageURLs,
  } = useContext(DataContext);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [addVariantOpen,toggleAddVariantOpen] = useToggle(null);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedVariantLength,setSelectedVariantLength]=useState(null)
  const [selectedVariantWidth,setSelectedVariantWidth]=useState(null)
  const [selectedVariantHeight,setSelectedVariantHeight]=useState(null)
  const [selectedVariantName,setSelectedVariantName]=useState(null)
  const [selectedVariantColor,setSelectedVariantColor]=useState(null)
  const [selectedVariantMaterial,setSelectedVariantMarterial]=useState(null)
  const [selectedVariantPrice,setSelectedVariantPrice]=useState(null)
  const [selectedVariantWeight,setSelectedVariantWeight]=useState(null)
  const [selectedVariantQuantity,setSelectedVariantQuantity]=useState(null)

  const handleSetSelectedCategory = (e) => {
    let categoryId = parseInt(e.target.value);

    setSelectedCategory(categoryId);
    let category =
      props.currentCategories &&
      props.currentCategories.find((cat) => cat.category_id === categoryId);
    if (category) {

      setSubcategories(category.subcategories || []);
    } else {
      setSubcategories([]);
    }
  };

  const handleSetSelectedSubcategory = (e) => {
    let subcategoryId = parseInt(e.target.value);

    setSelectedSubcategory(subcategoryId);
  };
  const moveUpImage = (index) => {
    if (index > 0) {
      const updatedPreviews = [...newProductImages];
      [updatedPreviews[index - 1], updatedPreviews[index]] = [
        updatedPreviews[index],
        updatedPreviews[index - 1],
      ];
      setNewProductImages(updatedPreviews);
      const previews = [];
      for (let i = 0; i < updatedPreviews.length; i++) {
        const file = updatedPreviews[i];
        const reader = new FileReader();
        reader.onload = (e) => {
          const previewUrl = e.target.result;
          previews.push(previewUrl);
          if (previews.length === updatedPreviews.length) {
            setCurrentImageURLs(previews);
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  // Function to move an image down in the order
  const moveDownImage = (index) => {
    if (index < newProductImages.length - 1) {
      const updatedPreviews = [...newProductImages];
      [updatedPreviews[index], updatedPreviews[index + 1]] = [
        updatedPreviews[index + 1],
        updatedPreviews[index],
      ];
      setNewProductImages(updatedPreviews);
      const previews = [];
      for (let i = 0; i < updatedPreviews.length; i++) {
        const file = updatedPreviews[i];
        const reader = new FileReader();
        reader.onload = (e) => {
          const previewUrl = e.target.result;
          previews.push(previewUrl);
          if (previews.length === updatedPreviews.length) {
            setCurrentImageURLs(previews);
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };




  const handleToggleAddVariantOpen = (e)=>{
    if(e){
      toggleAddVariantOpen(e)
    }
    else{
      toggleAddVariantOpen()
    }
  }

  const handleVariantSelected = (e) => {
    // Find the selected variant from the productVariants array
    let selectedVariant = props.variants.find(
      (variant) => variant.name === e.target.value
    );
    // If the variant is found, update the corresponding states
    if (selectedVariant) {
      setSelectedVariantName(selectedVariant.name);
      setSelectedVariantColor(selectedVariant.color);
      setSelectedVariantMarterial(selectedVariant.material);
      setSelectedVariantPrice(selectedVariant.price);
      setSelectedVariantWeight(selectedVariant.weight);
      setSelectedVariantQuantity(selectedVariant.quantity);
      setSelectedVariantLength(selectedVariant.length)
      setSelectedVariantWidth(selectedVariant.width)
      setSelectedVariantHeight(selectedVariant.height)
    }
  };


  return (
    <>
      {props.modal_open && props.modal_open === true ? (
        <>
          <div
            style={{
              position: "fixed",
              left: "34vw",
              top: "3vh",
              width: "40vw",
              height: "95vh",
              backgroundColor: "ghostwhite",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "top",
              overflowY:'scroll',
              boxShadow: "1px 1px 6px 2px black",
              zIndex: "999",
            }}
          >
            <div
              style={{
                position: "absolute",
                right: "2%",
                top: "2%",
                width: "2vh",
                height: "2vh",
                // backgroundColor: "black",
              }}
              onClick={() => props.modal_handler()}
            >
              <img
                style={{
                  width: "auto",
                  height: "2vh",
                  margin: "auto",
                }}
                src={closeIcon}
              />
            </div>
            <h2>{props.title}</h2>
            <div
              style={{
                width: "100%",
                height: "10vh",
                // backgroundColor:'grey',
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <h2
                style={{
                  marginLeft: "1vw",
                }}
              >
                {"Product Name:"}
              </h2>
              <input
                type="text"
                value={selectedVariantName&&selectedVariantName!==null?selectedVariantName:props.newProductName}
                onChange={(e) => props.handleSetNewProductName(e)}
                style={{
                  height: "50%",
                  width: "65%",
                  marginLeft: "2vw",
                  boxShadow: "1px 1px 6px 2px grey",
                }}
              />
            </div>

            <div
              style={{
                width: "100%",
                height: "10vh",
                // backgroundColor:'grey',
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <h2
                style={{
                  marginLeft: "1vw",
                  width: "24.5%",
                }}
              >
                {"Product Description:"}
              </h2>
              <input
                type="text"
                value={props.newProductDesc}
                onChange={(e) => props.handleSetNewProductDesc(e)}
                style={{
                  height: "50%",
                  width: "65%",
                  marginLeft: "1vw",
                  boxShadow: "1px 1px 6px 2px grey",
                }}
              />
            </div>
            <div
              style={{
                width: "100%",
                height: "10px",
                marginBottom:'1vh',
                backgroundColor:'black'
              }}
            ></div>


            <div
              style={{
                width: "100%",
                height: "10vh",
                // backgroundColor:'grey',
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <h2
                style={{
                  marginLeft: "1vw",
                  width: "10%",
                }}
              >
                {"Color:"}
              </h2>
              <input
                type="text"
                value={selectedVariantColor&&selectedVariantColor!==null?selectedVariantColor:props.newProductColor}
                onChange={(e) => props.handleSetNewProductColor(e)}
                style={{
                  height: "50%",
                  width: "18%",
 
                  boxShadow: "1px 1px 6px 2px grey",
                }}
              />
              <h2
                style={{
                  marginLeft: "1vw",
                  width: "14%",
                }}
              >
                {"Material:"}
              </h2>
              <input
                type="text"
                value={selectedVariantMaterial&&selectedVariantMaterial!==null?selectedVariantMaterial:props.newProductMaterial}
                onChange={(e) => props.handleSetNewProductMaterial(e)}
                style={{
                  height: "50%",
                  width: "18%",
                  marginRight:'2vw',
                  boxShadow: "1px 1px 6px 2px grey",
                }}
              />


                <button
                  onClick={()=>props.handleAddVariant()}
                >
                  add variant
                </button>
            </div>

            <div
              style={{
                width: "95%",
                height: "10vh",
                // backgroundColor:'grey',
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "left",
                padding: "1vw",

              }}
            >
              <h2
                style={{

                  width: "auto",
                }}
              >
                {"Length:"}
              </h2>
              <input
                type="number"
                step={0.25}
                value={selectedVariantLength&&selectedVariantLength!==null?selectedVariantLength:props.newProductLength}
                onChange={(e) => props.handleSetNewProductLength(e)}
                style={{
                  height: "50%",
                  width: "18%",

                  boxShadow: "1px 1px 6px 2px grey",
                }}
              />
              <h2
                style={{
                  marginLeft: "1vw",
                  width: "Auto",
                }}
              >
                {"Width:"}
              </h2>
              <input
                type="number"
                step={0.25}
                value={selectedVariantWidth&&selectedVariantWidth!==null?selectedVariantWidth:props.newProductWidth}
                // placeholder="ex: 2x2x4 in"
                onChange={(e) => props.handleSetNewProductWidth(e)}
                style={{
                  height: "50%",
                  width: "18%",

                  boxShadow: "1px 1px 6px 2px grey",
                }}
              />
              <h2
                style={{
                  marginLeft: "1vw",
                  width: "15%",
                }}
              >
                {"Height:"}
              </h2>
              <input
                type="number"
                step={0.25}
                value={selectedVariantHeight&&selectedVariantHeight!==null?selectedVariantHeight:props.newProductHeight}
                // placeholder="ex: 2x2x4 in"
                onChange={(e) => props.handleSetNewProductHeight(e)}
                style={{
                  height: "50%",
                  width: "18%",

                  boxShadow: "1px 1px 6px 2px grey",
                }}
              />
            </div>

            <div
              style={{
                width: "95%",
                height: "10vh",
                // backgroundColor:'grey',
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "left",
                padding:'1vw'
              }}
            >
              <h2
                style={{

                  width: "10%",
                }}
              >
                {"Price:"}
              </h2>

              <input
                type="number"
                step={0.01}
                value={selectedVariantPrice&&selectedVariantPrice!==null?selectedVariantPrice:props.newProductPrice}
                onChange={(e) => props.handleSetNewProductPrice(e)}
                style={{
                  height: "50%",
                  width: "18%",

                  boxShadow: "1px 1px 6px 2px grey",
                }}
              />

              <h2
                style={{
                  marginLeft: "1vw",
                  width: "10%",
                }}
              >
                {"Weight:"}
              </h2>

              <input
                type="number"
                value={selectedVariantWeight&&selectedVariantWeight!==null?selectedVariantWeight:props.newProductWeight}
                onChange={(e) => props.handleSetNewProductWeight(e)}
                step={0.01}
                style={{
                  height: "50%",
                  width: "18%",
                  marginLeft: "1vw",
                  boxShadow: "1px 1px 6px 2px grey",
                }}
              />
              <h2
                style={{
                  marginLeft: "1vw",
                  width: "15%",
                }}
              >
                {"Quantity:"}
              </h2>

              <input
                type="number"
                value={selectedVariantQuantity&&selectedVariantQuantity!==null?selectedVariantQuantity:props.newProductQuantity}
                onChange={(e) => props.handleSetNewProductQuantity(e)}
                step={1}
                style={{
                  height: "50%",
                  width: "18%",

                  boxShadow: "1px 1px 6px 2px grey",
                }}
              />
            </div>


            <div
              style={{
                width: "100%",
                height: "10vh",
                backgroundColor:'lightyellow',
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
            <h2
                style={{
                  marginLeft: "1vw",
                  width: "14%",
                }}
              >
                {"Variants:"}
              </h2>
      <select id="variantSelect"  onChange={(e)=>handleVariantSelected(e)}>
        <option value="" disabled>Select a variant</option>
        {props.variants.map((variant, index) => (
          <option key={index} value={variant.name}>
            {`${variant.name} - $${variant.price} x ${variant.quantity}`}
          </option>
        ))}
      </select>
            </div>





            <div
              style={{
                width: "100%",
                height: "10vh",
                // backgroundColor:'grey',
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <h2
                style={{
                  marginLeft: "1vw",
                  width: "21%",
                }}
              >
                {"Category:"}
              </h2>
              {/* 
              <input
                type="text"
                value={props.newProductCategory}
                onChange={(e) => props.handleSetNewProductCategory(e)}
                step={0.01}
                style={{
                  height: "50%",
                  width: "18%",
                  marginLeft: "1vw",
                  boxShadow: "1px 1px 6px 2px grey",
                }}
              /> */}

              <select
                style={{
                  marginRight: "4vw",
                  width: "21%",
                }}
                onChange={(e) => handleSetSelectedCategory(e)}
                id="categories"
              >
                <option value="">Select a category</option>
                {props.currentCategories.map((category) => (
                  <option
                    key={category.category_id}
                    value={category.category_id}
                  >
                    {category.category_name}
                  </option>
                ))}
              </select>
              <h2
                style={{
                  marginRight: "1vw",
                }}
              >
                {"Subcategory:"}
              </h2>
              <select
                onChange={(e) => handleSetSelectedSubcategory(e)}
                id="subcategories"
                disabled={!selectedCategory}
              >
                <option value="">Select a subcategory</option>
                {subcategories &&
                  subcategories.map((subcategory) => (
                    <option key={subcategory.id} value={subcategory.id}>
                      {subcategory.name}
                    </option>
                  ))}
              </select>
            </div>


              {/* {addVariantOpen&&addVariantOpen===true?
              <>
                            <div
              style={{
                width: "95%",
                height: "4vh",
                backgroundColor: "lightgreen",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "left",
                padding:'1vw'
              }}
            >

                <label
                style={{
                  fontSize: "1rem",
                  color: "black",
                  fontWeight: 600,
                  textAlign: "center",
                  cursor: "pointer",

                }}
              >
                  Name
                </label>
                <input
                type="text"
                // value={props.newProductWeight}
                // onChange={(e) => props.handleSetNewProductWeight(e)}

                style={{
                  height: "3vh",
                  width: "18%",

                  boxShadow: "1px 1px 6px 2px grey",
                }}
              />

                <label
                style={{
                  fontSize: "1rem",
                  color: "black",
                  fontWeight: 600,
                  textAlign: "center",
                  cursor: "pointer",

                }}
              >
                  Name
                </label>
                <input
                type="text"
                value={props.newProductWeight}
                // onChange={(e) => props.handleSetNewProductWeight(e)}

                style={{
                  height: "3vh",
                  width: "18%",

                  boxShadow: "1px 1px 6px 2px grey",
                }}
              />

            </div>
              </>
              :
              null
              } */}

            <div
              style={{
                width: "100%",
                height: "4vh",
                backgroundColor: "grey",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <h2
                style={{
                  marginLeft: "1vw",
                  width: "24.5%",
                }}
              >
                {"Images:"}
              </h2>

              <input
                type="file"
                accept=".jpg, .png, .jpeg,"
                onChange={(e) => props.handleSetNewProductImages(e)}
                multiple
                // value={props.newProductQuantity}
                // onChange={(e)=>props.handleSetNewProductQuantity(e)}
                // step={1}
                // style={{
                //   height:'50%',
                //   width:'18%',
                //   marginLeft:'1vw',
                //   boxShadow:"1px 1px 6px 2px grey",
                // }}
              />
            </div>




            <div
              style={{
                width: "100%",
                height: "28vh",
                backgroundColor: "lightgrey",
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                marginBottom: "1vh",
                overflowX: "scroll",
                overflowY: "scroll",
              }}
            >
              {/* <img src={currentImageURLs[0]}/> */}
              {currentImageURLs &&
                currentImageURLs.map((row, index) => {

                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "1vw",
                        width: "5rem",
                        height: "6rem",
                        marginLeft: "1vw",
                      }}
                    >
                      <img
                        style={{
                          width: "5rem",
                          height: "6rem",
                          // margin:'auto'
                        }}
                        src={row}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          height: "3vh",
                          width: "100%",
                          backgroundColor: "grey",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "1vw",
                            height: "auto",
                            backgroundColor: "lightgrey",
                            marginLeft: ".5vw",
                            marginRight: ".5vw",
                            // marginBottom:'1vh'
                          }}
                        >
                          {index}
                        </div>

                        <img
                          src={leftIcon}
                          style={{ width: ".70vw", marginRight: ".5vw" }}
                          onClick={() => moveUpImage(index)}
                        />
                        <img
                          src={rightIcon}
                          style={{ width: ".70vw" }}
                          onClick={() => moveDownImage(index)}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>

            <div
              style={{
                width: "100%",
                height: "10vh",
                // backgroundColor:'grey',
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "2vh",
              }}
            >
              <StyledButton
                buttonText={"Cancel"}
                buttonAction={() => props.handleCancelProduct()}
              />
              <StyledButton
                buttonText={"Create"}
                buttonAction={() =>
                  props.handleCreateProduct(
                    selectedCategory,
                    selectedSubcategory,
                  )
                }
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export const ProductWidget = (props) => {
  const {
    setTempIndex,
    shoppingCart,
    setShoppingCart,
    handleAddItemToCart,
    mobile,
  } = useContext(DataContext);

  useEffect(() => {
    if (props&&props.variants.length>0){
      setvariantName(props.variants[0].name)
    }
    else{

    }
    

  }, [props]);

  const [addQuantity, setAddQuantity] = useState(0);
  const [variantName, setvariantName] = useState(null);
  const [variantQuantity, setvariantQuantity] = useState(null);
  const [variantPrice, setvariantPrice] = useState(null);
  const [variantLength, setvariantLength] = useState(null);
  const [variantWidth, setvariantWidth] = useState(null);
  const [variantHeight, setvariantHeight] = useState(null);
  const [variantWeight, setvariantWeight] = useState(null);


  const handleSetAddQuantity = (e, max, op = null) => {
    if (!op) {
      setAddQuantity(e.target.value);
    } else {
      let tempMax=variantQuantity&&variantQuantity?variantQuantity:max
      let tempQuant = addQuantity;
      if (op === "+") {
        if (tempQuant +1 <= tempMax){
          tempQuant += 1;
        }
        else{
          return
        }
      } else if (op === "-") {
        if (tempQuant -1 >0){
          tempQuant -= 1;
        }
        else{
          return
        }
      }
      setAddQuantity(tempQuant);
    }
  };

  const cycleUpImage = (e) => {
    e.stopPropagation();

    if (props.imageIndex < props.images.length - 1) {
      props.setImageIndex((count) => count + 1);
    } else {
      props.setImageIndex(0);
    }

    if (props.modalState === true) {
      props.handleTogglePreviewModalOpen(e);
    }
  };

  // Function to move an image down in the order
  const cycleDownImage = (e) => {
    e.stopPropagation();
    if (props.modalState === true) {
      props.handleTogglePreviewModalOpen(e);
    }

    if (props.imageIndex > 0) {
      props.setImageIndex((count) => count - 1);
    } else {
      props.setImageIndex(props.images.length - 1);
    }
  };

  const handleAddItem = (
    e,
    product_id,
    product_name,
    images,
    product_price,
    quantity,
    length,
    width,
    height,
    weight
  ) => {

    handleAddItemToCart(
      e,
      product_id,
      product_name,
      images,
      variantPrice&&variantPrice!==null?variantPrice:product_price,
      [quantity[0],variantQuantity&&variantQuantity!==null?variantQuantity:quantity[1]],
      variantLength&&variantLength!==null?variantLength:length,
      variantWidth&&variantWidth!==null?variantWidth:width,
      variantHeight&&variantHeight!==null?variantHeight:height,
      variantWeight&&variantWeight!==null?variantWeight:weight,
      variantName&&variantName!==null?variantName:null
    );
    setAddQuantity(0);
  };

  const handleCloseModal=()=>{
    props.handleToggleProductModal(false)
    props.handleUnselectProduct()
  }

  const handleSelectedvariant=(e)=>{
    let index= parseInt(e.target.value)
    let selectedVariant=props.variants[index]
    setvariantName(selectedVariant.name)
    setvariantQuantity(selectedVariant.quantity)
    setvariantPrice(selectedVariant.price)
    setvariantLength(selectedVariant.length)
    setvariantWidth(selectedVariant.width)
    setvariantHeight(selectedVariant.height)
    setvariantWeight(selectedVariant.weight)
  }

  return (
    <div
      style={{
        position:'fixed',
        top:mobile?'16vh':'18vh',
        left:mobile?'7vw':'39vw',
        width: mobile ? "80%" : "20vw",
        maxWidth: mobile ? "100%" : "25vw",
        
        height: mobile ? "auto" : "auto",
        minHeight:'50vh',
        display: "flex",
        flexDirection: "column",
        marginBottom: "3vw",
        padding: mobile ? "3vw" : "1.5vw",
        backgroundColor:
          props.selectedProductID === props.product_id ? "#87CEFA" : "white",
        boxShadow: "1px 1px 6px 2px black",
        alignItems: "center",
      }}
      value={props.product_id}
      key={props.product_id}
    >

<ImagePreviewModal
            modalHandler={props.handleTogglePreviewModalOpen}
            modalState={props.previewModalOpen}
            previewImages={props.previewImages}
            imageIndex={props.imageIndex}
            setImageIndex={props.setImageIndex}
          />



                    <div
                style={{
                  position: "absolute",
                  right: "2%",
                  top: mobile ? ".5vh" : "2%",
                  width: "2vh",
                  height: "2vh",
                  // backgroundColor: "black",
                }}
                onClick={(e) =>handleCloseModal()}
              >
                <img
                  style={{
                    width: "auto",
                    height: "2vh",
                    margin: "auto",
                  }}
                  src={closeIcon}
                />
              </div>
      <div
        style={{
          width: "18vw",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          // backgroundColor:'green'
          marginTop:'1vh'
        }}
      >
        <img
          src={leftIcon}
          style={{ width: ".70rem", marginRight: ".5rem", marginLeft: ".5rem" }}
          onClick={(e) => cycleDownImage(e)}
        />

        <img
          style={{
            height: mobile ? "6rem" : "10rem",
            width: "auto",

            boxShadow: props.images[props.imageIndex]
              ? "1px 1px 6px 2px grey"
              : null,
          }}
          src={
            props.images[props.imageIndex] && props.images[props.imageIndex]
              ? props.images[props.imageIndex].thumbnail_url
              : null
          }
          onClick={(e) => props.handleTogglePreviewModalOpen(e, props.images)}
        />

        <img
          src={rightIcon}
          style={{ width: ".70rem", marginLeft: ".5rem", marginRight: ".5rem" }}
          onClick={(e) => cycleUpImage(e)}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
          width: "100%",
          // backgroundColor:'red',
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "1vh",
          marginTop: "1vh",
        }}
      >
        {/* <strong>{"Name:"}</strong> */}

        <label
          style={{
            fontSize: mobile ? "1rem" : "1.25rem",
            // backgroundColor:'green',
            color: "black",
            fontWeight: mobile ? 500 : 700,
            textAlign: "center",
            // marginBottom: "2vh",
          }}
        >
          <strong>{props.product_name}</strong>
        </label>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
          width: "100%",
          alignItems: "center",
          marginBottom: "1vh",
          textAlign: "center",
        }}
      >
        {!mobile ? (
          <>
            <label
              style={{
                fontSize: mobile ? "1rem" : "1.25rem",
                color: "black",
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              <strong>{"Description:"}</strong>
            </label>
          </>
        ) : null}

        <label
          style={{
            fontSize: mobile ? ".8rem" : "1.1rem",
            color: "black",
            fontWeight: 500,
            textAlign: "center",
            marginBottom:'1vh'
          }}
        >
          {props.product_desc}
        </label>
      </div>

      <label style={{marginBottom:'1vh'}}><strong>Select Product Variant:</strong></label>
          <select style={{marginBottom:'1vh'}} onChange={(e)=>handleSelectedvariant(e)}>
            {props.variants&&props.variants.map((variant, idx) => (
              <option key={idx} value={idx}>
                {variant.name} - ${variant.price}
              </option>
            ))}
          </select>


      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
          width: "100%",
          alignItems: "center",
          marginBottom: "1vh",
        }}
      >
        <label
          style={{
            fontSize: mobile ? ".9rem" : "1.1rem",
            color: "black",
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          <strong>{"Material:"}</strong>
        </label>
        <label
          style={{
            fontSize: mobile ? ".8rem" : "1.1rem",
            color: "black",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {props.product_material}
        </label>
      </div>
      {/* value={selectedVariant ? selectedVariant.name : ""} onChange={handleVariantChange} */}


      {/* 
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
          width: "100%",
          justifyContent: "center",
          marginBottom: "1vh",
        }}
      >


        <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "auto",
          width: "100%",
          justifyContent: "center",
          marginBottom: "1vh",
        }}
      >
          
        <label
          style={{
            marginRight: ".5vw",
            fontSize: mobile?'.8rem':"1.25rem",
          }}
        >
          <strong>{`Weight:`}</strong>
        </label>

        <label
          style={{
            fontSize: mobile?'.8rem':"1.25rem",
            marginRight: "1vw",
          }}
        >
           {`${props.product_weight} grams`}
        </label>
          
         
        </div>
      </div> */}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "2vh",
        }}
      >
        <div style={{ marginRight: "1vw", marginBottom: "1vh" }}>
          <strong>{`In Stock: `}</strong>
          {variantQuantity&&variantQuantity!==null?variantQuantity:props.product_quantity}
        </div>
        <div style={{ marginRight: "1vw" }}>
          <label
            style={{
              marginRight: ".5vw",
              fontSize: mobile ? "1.25rem" : "1.25rem",
            }}
          >
            <strong>{`Price: $`}</strong>
          </label>

          <label
            style={{
              fontSize: mobile ? "1.25rem" : "1.25rem",
              marginRight: "1vw",
            }}
          >
            {variantPrice&&variantPrice!==null?variantPrice:props.product_price}
          </label>
        </div>
      </div>

      {props.mode === "store" ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "auto",
              alignItems: "center",
              justifyContent: "center",
              gap: "2vw",
              // backgroundColor:'grey'
            }}
          >
            {mobile ? (
              <>
                <label
                  style={{
                    // backgroundColor:'red',
                    height: "auto",
                    fontSize: mobile ? "1.3rem" : "1.25rem",
                    textAlign: "center",
                    padding: "0",
                  }}
                >
                  Quantity: {addQuantity}
                </label>
                <button onClick={(e) => handleSetAddQuantity(e,props.product_quantity, "-")}>-</button>

                <button onClick={(e) => handleSetAddQuantity(e,props.product_quantity, "+")}>+</button>
              </>
            ) : (
              <>
                <input
                  value={addQuantity}
                  onChange={(e) => handleSetAddQuantity(e)}
                  type="number"
                  min={0}
                  max={props.product_quantity}
                  style={{
                    width: "8vw",
                    marginRight: "2vw",
                  }}
                ></input>
              </>
            )}

            <button
              onClick={(e) =>
                handleAddItem(
                  e,
                  props.product_id,
                  props.product_name,
                  props.images,
                  props.product_price,
                  [addQuantity,props.product_quantity],
                  props.product_length,
                  props.product_width,
                  props.product_height,
                  props.product_weight,
                )
              }
            >
              {"Add to Cart"}
            </button>
          </div>
        </>
      ) : (
        <>

        </>
      )}
    </div>
  );
};

export const ImagePreviewModal = (props) => {
  const { tempIndex, setTempIndex, mobile } = useContext(DataContext);

  useEffect(() => {
    console.log(props)
  }, [props]);

  return (
    <>
      {props.modalState === true ? (
        <>
          <div
            style={{
              position: "fixed",


              margin:'auto',
              top: mobile ? "20vh" : "5vw",
              // left:mobile?'8vw':'30vw',
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "auto",
              height: mobile ? "auto" : "75vh",
              backgroundColor: "ghostwhite",
              padding:mobile?'1vw':'1vw',
              zIndex: "9999",
              boxShadow: "1px 1px 6px 2px black",
            }}
            onClick={(e) => props.modalHandler(e)}
          >
            <div
              style={{
                width: "auto",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                // backgroundColor:'green'
              }}
            >
              <img
                style={{
                  height: "75vh",
                  width: "auto",
                  maxHeight: mobile ? "80vw" : "",
                  maxWidth: mobile ? "98vw" : "",
                  margin: "auto",
                }}
                src={props.previewImages[props.imageIndex].url}
                // onClick={(e)=>props.modalHandler(e,props.previewImages[imageIndex].url,imageIndex)}
              />
            </div>

            {/* 

          <img 
          style={{
            width:'auto',
            height:'90%',
            margin:'auto',
            }}src={props.previewURL}
          /> */}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

// export const ControlButtonsWidget=(props)=>{
//     return(
//         <>
//               <div
//                 style={{
//                   width:'20%',
//                   display:'flex',
//                   flexDirection:'column',
//                   width:'22%',
//                   height:'80%',
//                   backgroundColor:'white',
//                   marginLeft:'2vw',

//                 }}
//               >
//                 <div
//                   style={{

//                     display:'flex',
//                     flexDirection:'row',
//                     width:'100%',
//                     height:'25%',
//                     // backgroundColor:'green',
//                     alignItems:'center',
//                     marginBottom:'1vh',
//                     paddingLeft:'1vw'

//                   }}
//                 >
//                   <h1>
//                     {<strong>Orders:</strong>}
//                   </h1>
//                 </div>

//                 <div
//                   style={{

//                     display:'flex',
//                     flexDirection:'row',
//                     width:'100%',
//                     height:'20%',
//                     // backgroundColor:'lightblue',
//                     alignItems:'center',
//                     marginBottom:'1vh',
//                     paddingLeft:'1vw'

//                   }}
//                 >
//                   <h2>
//                     {<strong>New:</strong>}
//                   </h2>
//                 </div>

//                 <div
//                   style={{

//                     display:'flex',
//                     flexDirection:'row',
//                     width:'100%',
//                     height:'20%',
//                     // backgroundColor:'lightcoral',
//                     alignItems:'center',
//                     marginBottom:'1vh',
//                     paddingLeft:'1vw'
//                   }}
//                 >
//                   <h2>
//                     {<strong>Completed:</strong>}
//                   </h2>
//                 </div>

//                 <div
//                   style={{

//                     display:'flex',
//                     flexDirection:'row',
//                     width:'100%',
//                     height:'20%',
//                     // backgroundColor:'lightyellow',
//                     alignItems:'center',
//                     paddingLeft:'1vw'
//                   }}
//                 >
//                   <h2>
//                     {<strong>Refunded:</strong>}
//                   </h2>
//                 </div>

//               </div>
//         </>
//     )
// }
