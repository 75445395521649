import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../common/AuthContext";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../common/DataContext";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { poster } from "../../calls/calls";
import { API_URL } from "../../common/constants";
import { Navigate } from "react-router-dom";

export const Login = () => {
  const {
    logInUser,
    email,
    setEmail,
    password,
    setPassword,
    user,
    redirect,
    toggleRedirect,
  } = useContext(AuthContext);

  const { mobile, handleNavigate } = useContext(DataContext);
  const [code, setCode] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.role == "Admin") {
      handleNavigate("/AdminDashboard");
    }
  }, [user]);

  const handleLogin = () => {
    logInUser();
  };

  return (
    <>
      <Header />
      <div
        className="adminSettings"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1>Login</h1>
        <form>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: "1vh",
            }}
          >
            <div style={{ width: "5vw" }}>
              <label>Email: </label>
            </div>

            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id=""
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ width: "5vw" }}>
              <label>Password: </label>
            </div>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              id=""
            />
          </div>
        </form>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "2vh",
          }}
        >
          <button type="button" onClick={() => handleLogin()}>
            Submit
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};
