import React from "react";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { ContactText } from "./contactText";
// import "./styles.css";
import { ShoppingCartModal } from "../../components/common";

export const Contact = () => {
  return (
    <>
      <Header />
      <ShoppingCartModal />
      <div className="contactPage"
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          marginTop: "0vh",
          margin: "0%",
          padding: "0px",
          overflow: "auto",
          backgroundColor: "rgb(78, 171, 218)",
          /* background-color: rgba(233, 239, 243, 0.29); */
          backgroundSize: "cover",    
        }}
      >
        <div>
          <ContactText />
          
        </div>
      </div>
      <Footer />
    </>
  );
};
